import React, { useState } from "react";
import { NavLink, useNavigate, useSubmit } from "react-router-dom";
import classes from "./CertificationNavigation.module.css";
import {
  HOME_BUTTON,
  LOGOUT_TEXT,
  PRODUCTIONS_TITLE,
  USER_SETTINGS_TITLE,
} from "../utils/constants";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import ProductionForm from "./ProductionForm";
interface CertificationNavigationProps {
  title: string;
}

const CertificationNavigation: React.FC<CertificationNavigationProps> = ({
  title,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false); // Estado para controlar la apertura del modal
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const submit = useSubmit();

  const getEmail = () => {
    let email = "";
    if (token) {
      const tokenPayload = token.split(".")[1]; // Obtenemos la segunda parte del token (la que contiene la información)
      const decodedToken = JSON.parse(atob(tokenPayload)); // Decodificamos la información en formato base64 y la convertimos a JSON
      email = decodedToken?.user?.email; // Extraemos el email del objeto decodificado
    }
    return email;
  };

  const getFullName = () => {
    const token = localStorage.getItem("token");
    let firstName = "";
    let lastName = "";
    if (token) {
      const tokenPayload = token.split(".")[1]; // Obtenemos la segunda parte del token (la que contiene la información)
      const decodedToken = JSON.parse(atob(tokenPayload)); // Decodificamos la información en formato base64 y la convertimos a JSON
      firstName = decodedToken?.user?.firstName; // Extraemos el email del objeto decodificado
      lastName = decodedToken?.user?.lastName; // Extraemos el email del objeto decodificado
    }
    return firstName + " " + lastName;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };

  function navigateUserSettings() {
    navigate("/user-settings");
  }

  const handleSignOut = async () => {
    setAnchorEl(null);
    submit(null, { action: "/logout", method: "post" });
    navigate("/");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleProductionsClick = async () => {
    setAnchorEl(null);
    navigate("/productions");
  };

  return (
    <header
      className={`flex justify-between items-center p-8 shadow-md ${
        token ? "fixed top-0 left-0 right-0 z-10" : ""
      }`}
      style={{
        boxShadow: token ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "0px 0px 0px",
        marginBottom: "20px",
        padding: "1rem",
        background: token ? "rgb(237, 244, 232)" : "transparent",
        height: "10vh",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip
          title={
            token ? "Volver a Producciones" : "Ir a la pagina web de RiceChain"
          }
          placement="bottom"
        >
          <div className="flex w-1/4 justify-center items-center">
            <NavLink to={token ? "/productions" : "/"}>
              <img
                src="../../Leaves.png"
                alt="Logo"
                className="max-w-96 cursor-pointer w-full sm:w-[80%]"
              />
            </NavLink>
          </div>
        </Tooltip>
        <Typography className={classes.headerTitle}>{title}</Typography>
      </div>

      <div
        style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
      ></div>
      {token ? (
        <IconButton
          aria-label="account"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.accountButton}
          style={{ color: "#333333" }}
        >
          <AccountCircle fontSize="large" />
        </IconButton>
      ) : (
        <div
          className="flex w-2/12 justify-center items-center max-lg:hidden"
          style={{ width: "140px" }}
        >
          {/* <MainButton onClick={handleLoginClick}>Ir a RiceChain web</MainButton> */}
        </div>
      )}

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "rgb(245, 250, 242)",
            margin: "0px 0 0 -20px",
            fontFamily: "system-ui",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/");
          }}
          className="text-[1.2rem]"
        >
          {HOME_BUTTON}
        </MenuItem>
        <MenuItem onClick={handleProductionsClick} className="text-[1.2rem]">
          {PRODUCTIONS_TITLE}
        </MenuItem>
        <MenuItem onClick={navigateUserSettings} className="text-[1.2rem] ">
          {USER_SETTINGS_TITLE}
        </MenuItem>
        <MenuItem onClick={handleSignOut} className="text-[1.2rem]">
          {LOGOUT_TEXT}
        </MenuItem>
        <Divider />
        <Typography
          style={{
            fontFamily: "system-ui",
            margin: "5px 15px",
            fontSize: "1.1rem",
            fontStyle: "italic",
          }}
        >
          {getFullName() + " - " + getEmail()}
        </Typography>
      </Menu>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="production-form-modal-title"
        aria-describedby="production-form-modal-description"
        // BackdropComponent={CustomBackdrop}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: "40px 30px",
              backgroundColor: "rgb(245, 250, 242)",
              width: "400px",
              borderRadius: "15px",
            }}
          >
            <ProductionForm />
          </Paper>
        </div>
      </Modal>
    </header>
  );
};
// const CustomBackdrop = (props: any) => {
//   return <Backdrop {...props} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />;
// };
export default CertificationNavigation;

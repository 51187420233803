import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";

interface Props {
  darkMode: boolean;
  values: string[] | undefined;
  selected?: string;
  disabled?: boolean;
  onChange: (event: string) => void;
}

const SingleSelectCheckbox: React.FC<Props> = ({
  darkMode,
  values,
  selected,
  disabled,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(
    selected ? selected : null
  );

  const handleChange = (val: string) => {
    setSelectedValue((prev) => (prev === val ? null : val));
    onChange(val);
  };

  return (
    <div className="xl:flex xl:flex-row mt-4 w-4 sm:w-60">
      {values?.map((value: string) => (
        <div key={value}>
          <FormControlLabel
            style={{
              border: "1.5px solid rgb(71 85 105 / 30%)",
              borderRadius: "16px",
              margin: "5px",
              height: "50px",
              fontFamily: "system-ui",
              textAlign: "left",
              minWidth: "90px",
              padding: "0 10px",
              background: "white",
            }}
            control={
              <Checkbox
                name="checkbox"
                checked={selectedValue === value}
                onChange={() => handleChange(value)}
                disabled={disabled}
                style={{
                  color: "#76885B",
                  borderWidth: "1.5px",
                  margin: "0px",
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 35 } }}
              />
            }
            label={value}
          />
        </div>
      ))}
    </div>
  );
};

export default SingleSelectCheckbox;

import React from "react";
import { Card, CardContent, Typography, Divider } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import SignupForm from "./SignupForm";
import LoginForm from "./LoginForm";
import { REGISTERED_USER_BUTTON, REGISTER_USER_TEXT } from "../utils/constants";

const AuthForm = () => {
  const [searchParams] = useSearchParams();
  const isSignUp = searchParams.get("mode") === "signup";

  return (
    <Card
      variant="outlined"
      style={{
        width: "100%",
        maxWidth: 400,
        borderRadius: 20,
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        background: '#f5faf2',
        marginTop: isSignUp ? "-8.5vh" : "5vh" 
      }}
    >
      <CardContent>
        <Typography variant="h5" align="center" gutterBottom style={{color: "#282928"}}>
          {isSignUp ? REGISTER_USER_TEXT : REGISTERED_USER_BUTTON}
        </Typography>
        <Divider style={{ margin: "16px 0" }} />
        {isSignUp ? <SignupForm /> : <LoginForm />}
      </CardContent>
    </Card>
  );
};

export default AuthForm;

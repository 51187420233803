import { Production } from '../models/Production.model';
import { ProductionFlow } from '../models/ProductionFlow.model';

export function createProductionModel(obj: any): Production {
    const productionModel: Production = {
        id: obj.id ? obj.id : undefined,
        name: obj.name ? obj.name : undefined,
        createdAt: obj.createdAt ? obj.createdAt : undefined,
        updatedAt: obj.updatedAt ? obj.updatedAt : undefined,
    };

    return productionModel;
}

export function createProductionListModel(obj: any): Production {
    obj.map((production: any) => {
        const productionModel: Production = {
            id: production.id ? production.id : undefined,
            name: production.name ? production.name : undefined,
            createdAt: production.createdAt ? production.createdAt : undefined,
            updatedAt: production.updatedAt ? production.updatedAt : undefined,
            completedStages: production.completedStages ? production.completedStages : undefined,
            completedStagesShow: production.completedStagesShow ? production.completedStagesShow : undefined
        };

        return productionModel;
    });

    return obj;
}

export function createProductionFlow(obj: any): ProductionFlow {
    const productionModel: ProductionFlow = {
        productionId: obj.productionId ?? undefined,
        name: obj.name ?? undefined,
        stages: obj.stages ?? undefined,
    };

    return productionModel;
}

import { json } from "react-router-dom";
import { CONNECTION_ERROR } from "../utils/constants";
import { FormAnswers } from "../models/FormAnswers.model";

export async function getStageFields(
  token: string | null,
  name: string | undefined,
  productionId: string | undefined
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/stage-form-answer?name=${name}&productionId=${productionId}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (!response.ok) {
      return json(
        { message: "No se pudieron cargar las producciones." },
        {
          status: 500,
        }
      );
    } else {
      const resData = await response.json();
      return resData;
    }
  } catch (error) {
    return json({ error: CONNECTION_ERROR });
  }
}

export async function postAnswers(
  token: string | null,
  formAnswersBody: FormAnswers,
  mode: string
) {
  try {
    const url =
      mode === "save"
        ? `${process.env.REACT_APP_BACKEND_URL}/stage-form-answer`
        : `${process.env.REACT_APP_BACKEND_URL}/stage-form-answer/certificate`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formAnswersBody),
    });

    if (!response.ok) {
      return json(
        { message: "No se pudieron cargar las respuestas." },
        {
          status: 500,
        }
      );
    } else {
      return response;
    }
  } catch (error) {
    return json({ error: CONNECTION_ERROR });
  }
}

import React, { ReactNode, Suspense } from "react";
import StageCard from "../components/StageCard";
import { Await, defer, useLoaderData } from "react-router-dom";
import { getAuthToken } from "../utils/Auth";
import { getProductionFlowCall } from "../services/Productions.service";
import { LOADING_TEXT } from "../utils/constants";
import ProductionHeader from "../components/ProductionHeader";
import Footer from "../components/Footer";

const ProductionPage: React.FC = () => {
  const { stages }: any = useLoaderData();

  return (
    <>
      <div>
        <ProductionHeader />
        <Suspense fallback={<div>{LOADING_TEXT}</div>}>
          <Await resolve={stages}>
            <div
              className="w-full"
              style={{
                marginLeft: "5%",
                maxWidth: "90%",
                marginTop: "15%",
                height: "100%",
              }}
            >
              <div className="grid grid-cols-4 gap-4 max-lg:grid-cols-1 justify-items-center items-center mx-auto py-8 w-3/2">
                {stages.stages.map((stage: any) => (
                  <StageCardWrapper key={stage.stageForm.name}>
                    <StageCard stage={stage} />
                  </StageCardWrapper>
                ))}
              </div>
            </div>
          </Await>
        </Suspense>
      </div>
      <div className="w-full md:absolute md:bottom-0">
        <Footer />
      </div>
    </>
  );
};

export default ProductionPage;

async function loadStages(id: string) {
  const token = getAuthToken();
  return await getProductionFlowCall(token, id);
}

export async function loader({ request, params }: any) {
  const id = params.id;
  return defer({
    stages: await loadStages(id),
  });
}

// Wrapper component to handle hover effect
const StageCardWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="stage-card-wrapper">{children}</div>;
};

// CSS para el hover effect
const styles = `
  .stage-card-wrapper:hover {
    transform: scale(1.05); // Aumenta el tamaño al 105% al pasar el mouse
    transition: transform 0.3s ease; // Agrega una transición suave
  }
`;

// Crear un elemento <style> para agregar los estilos
const styleElement = document.createElement("style");
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);

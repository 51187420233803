import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Form,
  useActionData,
  useNavigate,
  useNavigation,
  useSearchParams,
} from "react-router-dom";
import { resetPassValidation } from "../utils/AuthValidation";
import {
  CONFIRM_RESET_PASSWORD_TITLE,
  CONFIRM_RESET_PASSWORD_TEXT,
  CHECK_EMAIL,
  REGISTERED_USER_BUTTON,
} from "../utils/constants";
import PrincipalFormField from "./PrincipalFormField";

const ResetPassForm: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isReset = searchParams.get("mode") === "reset";
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const data: any = useActionData();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data && !data.error) {
      setOpen(true);
    }
  }, [data, setOpen]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.currentTarget.value);
  };

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(event.currentTarget.value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setConfirmPassword(event.currentTarget.value);
  };

  const validateInputs = (event: SyntheticEvent) => {
    const res = resetPassValidation({
      isReset,
      email,
      password,
      confirmPassword,
    });
    setErrors(res);
    if (res.email || res.password) {
      event.preventDefault();
    }
  };

  const redirectToLogin = () => {
    navigate("/auth");
  };

  const renderForm = isReset ? (
    <>
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Contraseña"
        onChange={handlePasswordChange}
        height="60px"
        width="80%"
        id="password"
        name="password"
        type="password"
      />
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Confirmar contraseña"
        onChange={handleConfirmPasswordChange}
        height="60px"
        width="80%"
        id="confirmPassword"
        name="confirmPassword"
        type="password"
      />
    </>
  ) : (
    <>
      {/* <TextField
    id="email"
    name="email"
    label="&nbsp;&nbsp;&nbsp;Email"
    variant="standard"
    InputLabelProps={{
      sx: {
        "&.Mui-focused, &.MuiInputLabel-shrink": {
          color: "gray",
          marginTop: "5px",
          marginLeft: "5px",
        },
      },
    }}
    InputProps={{
      disableUnderline: true,
      sx: {
        "& input": {
          padding: "10px",
          marginLeft: "5px",
          marginTop: "0px",
        },
      },
    }}
    onChange={handleEmailChange}
    className="w-full bg-white rounded-2xl"
    style={{
      border: "1.5px solid rgb(71 85 105 / 30%)",
      width: "80%",
      borderRadius: "16px",
      marginTop: "15px",
      marginBottom: "30px",
      height: "60px",
    }}
  /> */}
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Email"
        onChange={handleEmailChange}
        height="60px"
        width="80%"
        id="email"
        name="email"
      />
    </>
  );

  const confirmationComponent = isReset ? (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>{CONFIRM_RESET_PASSWORD_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{CONFIRM_RESET_PASSWORD_TEXT}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={redirectToLogin} autoFocus>
          {REGISTERED_USER_BUTTON}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity="success"
        sx={{ width: "100%" }}
        style={{
          borderRadius: "100px",
          marginBottom: "1rem",
          width: "80%",
          marginLeft: "10%",
          color: "#d32f2f",
        }}
      >
        {CHECK_EMAIL}
      </Alert>
    </Snackbar>
  );

  return (
    <Card
      variant="outlined"
      style={{
        width: "100%",
        maxWidth: 600,
        margin: "0 auto",
        borderRadius: 20,
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        background: "#f5faf2",
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          style={{ color: "#282928", paddingTop: "1rem" }}
        >
          Reestablecer contraseña
        </Typography>
        <Typography
          align="center"
          gutterBottom
          style={{ color: "#282928", fontSize: "1rem" }}
        >
          {isReset
            ? "Ingrese su nueva contraseña"
            : "Le enviaremos instrucciones para que puedas restablecer tu contraseña"}
        </Typography>
        <Divider style={{ margin: "1rem 0" }} />
        <Form
          method={isReset ? "put" : "post"}
          className="flex flex-col justify-center items-center"
          style={{
            marginRight: "1rem",
            marginLeft: "1rem",
          }}
        >
          {renderForm}
          {(errors.email || errors.password) && (
            <div className="text-left w-full">
              {Object.values(errors)
                .filter((err) => err !== "")
                .map((err) => (
                  <Alert
                    key={err}
                    severity="error"
                    style={{
                      borderRadius: "100px",
                      marginBottom: "1rem",
                      width: "80%",
                      marginLeft: "10%",
                      color: "#d32f2f",
                    }}
                  >
                    {err}
                  </Alert>
                ))}
            </div>
          )}
          {data && data.error && (
            <div className="text-left w-full">
              <Alert
                severity="error"
                style={{
                  borderRadius: "100px",
                  marginBottom: "1rem",
                  width: "80%",
                  marginLeft: "10%",
                  color: "#d32f2f",
                }}
              >
                {data.error}
              </Alert>
            </div>
          )}
          {confirmationComponent}
          <Button
            variant="contained"
            className="w-full h-12"
            style={{
              background: "#76885B",
              borderRadius: "50px",
              height: "50px",
              marginBottom: "1rem",
              fontFamily: "system-ui",
              fontSize: "1.2rem",
              padding: "0.5rem 1rem",
              letterSpacing: "1px",
              display: "inline-block",
              whiteSpace: "nowrap",
              fontWeight: "300",
              textTransform: "none",
              boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
              width: "80%",
            }}
            onClick={validateInputs}
            disabled={isSubmitting}
            color="success"
            type="submit"
          >
            {isSubmitting ? "Cargando..." : "Confirmar"}
          </Button>
        </Form>
      </CardContent>
    </Card>
  );
};

export default ResetPassForm;

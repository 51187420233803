import { Switch } from "@mui/material";
import React, { useState } from "react";

interface Props {
  placeholder?: string;
  selected?: string;
  disabled?: boolean;
  onChange: (newVal: string) => void;
}

const FormSwitch: React.FC<Props> = ({
  placeholder,
  selected,
  disabled,
  onChange,
}) => {
  const [checked, setChecked] = useState(
    selected ? selected === "true" : false
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChange(event.target.checked.toString());
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        marginLeft: "10%"
      }}
    >
      <Switch
        checked={checked}
        onChange={handleChange}
        color="success"
        disabled={disabled}
        sx={{
          transform: "scale(1.5)", // Ajusta el tamaño según tu preferencia
        }}
      />
      {/* <FormTextfield
        placeholder={placeholder ? placeholder : ""}
        darkMode={true}
        disabled={!checked}
      /> */}
    </div>
  );
};

export default FormSwitch;

import React, { Suspense, useRef } from "react";
import { Alert, IconButton, Typography } from "@mui/material";
import { Await, defer, useLoaderData, useLocation } from "react-router-dom";
import {
  CERTIFICATE_SUBTITLE,
  CERTIFICATIONS_NOT_FOUND,
  COPY_QR_MESSAGE,
  LOADING_TEXT,
  QR_TEXT,
} from "../utils/constants";
import CertificationTable from "../components/CertificationTable";
import { getCertificateCall } from "../services/Certification.service";
import { StageForm } from "../models/StageForm.model";
import QRCode from "react-qr-code";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";
import CertificationNavigation from "../components/CertificationNavigation";
import classes from "./Certificate.module.css";

const CertificatePage: React.FC = () => {
  const { certificate }: any = useLoaderData();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const productionName = queryParams.get("productionName") || "";
  const qrCodeRef = useRef<any>(null);
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const token = localStorage.getItem("token");

  const openNotification = () => {
    setNotificationOpen(true);
  };

  const closeNotification = () => {
    setNotificationOpen(false);
  };

  function handleDownloadClick() {
    if (qrCodeRef.current) {
      const svg = qrCodeRef.current.querySelector("svg");
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    } else {
      alert("QR Code container reference not available");
    }
  }

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function handlePrintClick() {
    if (qrCodeRef.current) {
      const svgElement = qrCodeRef.current.querySelector("svg");

      if (svgElement) {
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();

        img.onload = async () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx?.drawImage(img, 0, 0);
          const pngFile = canvas.toDataURL("image/png");
          const printWindow = window.open("", "_blank");

          if (printWindow) {
            printWindow.document.write(`
              <html>
                <head>
                  <title>Print QR Code</title>
                </head>
                <body>
                  <img src="${pngFile}" alt="QR Code" />
                </body>
              </html>
            `);

            printWindow.document.close();
            await sleep(1);
            printWindow.print();
          } else {
            alert("Unable to open print window");
          }
        };

        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
      } else {
        alert("SVG element not found within QR Code container");
      }
    } else {
      alert("QR Code container reference not available");
    }
  }

  function handleCopyClick() {
    if (qrCodeRef.current) {
      const svgElement = qrCodeRef.current.querySelector("svg");

      if (svgElement) {
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();

        img.onload = async () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx?.drawImage(img, 0, 0);
          const pngFile = canvas.toDataURL("image/png");

          try {
            await copyImageToClipboard(pngFile);
            openNotification();
          } catch (error) {
            console.error("Error al copiar el QR Code al portapapeles", error);
          }
        };

        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
      } else {
        alert("SVG element not found within QR Code container");
      }
    } else {
      alert("QR Code container reference not available");
    }
  }

  async function copyImageToClipboard(dataUrl: string) {
    const blob = await (await fetch(dataUrl)).blob();
    const clipboardData = new ClipboardItem({ "image/png": blob });
    await navigator.clipboard.write([clipboardData]);
  }

  return (
    <>
      <Suspense fallback={<div>{LOADING_TEXT}</div>}>
        <Await resolve={certificate}>
          <div
            className="bg-lime-100 min-h-screen overflow-auto relative"
            style={{ background: "rgb(245, 250, 242)" }}
          >
            {/* <div className="justify-start items-center" style={{background:"lightgray"}}>
              <Button
                onClick={handleGoBackClick}
                style={{
                  color: "black",
                  backgroundColor: "rgba(199, 253, 158, 0.959)",
                  margin: "45px 0 20px 45px",
                  fontFamily: "system-ui",
                  fontSize: "1.3vw",
                }}
                startIcon={<ChevronLeftIcon />}
              >
                {GO_BACK_BUTTON}
              </Button>
            </div> */}
            <div style={{ marginBottom: token ? "80px" : "80px" }}>
              <CertificationNavigation title={"Trazabilidad Agrícola"} />
            </div>
            <div
              className="flex flex-col items-center justify-center"
              style={{
                background: "rgb(245, 250, 242)",
                marginTop: token ? "100px" : "-50px",
              }}
            >
              <Typography variant="h4" className={classes.topTitle}>
                {productionName}
              </Typography>
              <div>
                <Typography className={classes.subtitle} variant="h4">
                  {CERTIFICATE_SUBTITLE}
                </Typography>
              </div>

              {certificate?.stageForms?.length === 0 && (
                <Typography
                  variant="h3"
                  style={{ textAlign: "left", margin: "0px 5px 10px 5px" }}
                >
                  {CERTIFICATIONS_NOT_FOUND}
                </Typography>
              )}

              {certificate?.stageForms?.map(
                (stageForm: StageForm, stageFormIndex: number) =>
                  stageForm && (
                    <div
                      className="flex items-center justify-center mt-5 w-full"
                      key={stageFormIndex}
                    >
                      <CertificationTable stageForm={stageForm} />
                    </div>
                  )
              )}

              <div className="max-md:space-y-2 md:space-x-2 sm:flex sm:flex-row items-start justify-center w-4/5 lg:w-1/2 mt-12">
                <div>
                  <Typography className={classes.QRText} variant="h5">
                    {QR_TEXT}
                  </Typography>
                </div>
                <div className="flex max-md:justify-center">
                  <div className="qrcode-container" ref={qrCodeRef}>
                    <QRCode value={window.location.href} size={200} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "10px",
                    }}
                  >
                    <IconButton onClick={handleDownloadClick}>
                      <DownloadIcon color="action" fontSize="medium" />
                    </IconButton>
                    <IconButton onClick={handlePrintClick}>
                      <PrintIcon color="action" fontSize="medium" />
                    </IconButton>
                    <IconButton onClick={handleCopyClick}>
                      <ContentCopyIcon color="action" fontSize="medium" />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end min-h-20">
              <img
                src="../../Leaves.png"
                alt="Leaves"
                className="w-1/4 mb-4 min-w-60"
              />
            </div>
            <Snackbar
              open={notificationOpen}
              autoHideDuration={6000}
              onClose={closeNotification}
            >
              <Alert
                elevation={6}
                severity="success"
                onClose={closeNotification}
              >
                {COPY_QR_MESSAGE}
              </Alert>
            </Snackbar>
          </div>
        </Await>
      </Suspense>
    </>
  );
};

export default CertificatePage;

async function loadCertificate(productionId: string) {
  return await getCertificateCall(productionId);
}

export async function loader({ request, params }: any) {
  const productionId = params.productionId;
  return defer({
    certificate: await loadCertificate(productionId),
  });
}

import { json, redirect } from "react-router-dom";
import { User } from "../models/User.model";
import { CONNECTION_ERROR } from "../utils/constants";

export async function resetPasswordCall(method: string, mode: string, authData: User) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/passwords/` + mode,
      {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(authData),
      }
    );

    switch (response.status) {
      case 400:
        return json({ error: "Existe un error en los datos ingresados." });
      case 401:
        return json({ error: "Usuario y/o contraseña incorrecto" });
      case 404:
        return json({ error: "El usuario no existe." });
      case 500:
        return json({ error: "No se pudo autenticar el usuario." });
      default:
        break;
    }

    return "ok";
  } catch (error) {
    return json({ error: CONNECTION_ERROR });
  }
}

export async function authenticationCall(mode: string, authData: User) {
    try {
      console.log(process.env.REACT_APP_BACKEND_URL);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/` + mode, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(authData),
        });
      
        switch (response.status) {
          case 400:
            return json({ error: "Existe un error en los datos ingresados." });
          case 401:
            return json({ error: "Usuario y/o contraseña incorrecto" });
          case 404:
            return json({ error: "El usuario no existe." });
          case 500:
            return json({ error: "No se pudo autenticar el usuario." });
          default:
            break;
        }
      
        if (mode === "signup") {
          return "ok";
        }
      
        const userInfo = await response.json();
        localStorage.setItem("token", userInfo.token);
        const { id, firstName, lastName } = userInfo.user;
        localStorage.setItem("user", JSON.stringify({ id, firstName, lastName }));
        const expiration = new Date();
        expiration.setDate(expiration.getDate() + 1);
        localStorage.setItem("expiration", expiration.toISOString());
        return redirect("/productions");
      } catch (error) {
        return json({ error: CONNECTION_ERROR });
      }
}

import { useNavigate, useRouteLoaderData } from "react-router-dom";
import CompanyInformation from "../components/CompanyInformation";
import CompanyContact from "../components/CompanyContact";
import Footer from "../components/Footer";
import React from "react";
import { CONTENT_TEXT } from "../utils/constants";
import Header from "../components/Header";
import Info from "../components/Info";

const HomePage: React.FC = () => {
  const token: string = useRouteLoaderData("root") as string;
  const navigate = useNavigate();

  function handleLoginClick() {
    navigate("/auth");
  }

  return (
    <>
      <div style={{ height: "100vh", overflowY: "scroll" }}>
        <Header
          token={token}
          handleLoginClick={handleLoginClick}
        />
        <Info contentText={CONTENT_TEXT} />
        <CompanyInformation />
        <CompanyContact />
        <Footer />
      </div>
    </>
  );
};

export default HomePage;

import {
  Alert,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
//import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import React, { useState } from "react";
import { contactValidation } from "../utils/AuthValidation";
import {
  CONTACT_TITLE,
  SEND_TEXT,
  MESSAGE_SENT,
  CONNECTION_ERROR,
  UNEXPECTED_ERROR,
} from "../utils/constants";
import { Contact } from "../models/Contact.model";
import { contactCall } from "../services/Contact.service";
import PrincipalFormField from "./PrincipalFormField";

const CompanyContact: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState({ email: "", fields: "", system: "" });
  const [open, setOpen] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.currentTarget.value);
  };

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.currentTarget.value);
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMessage(event.currentTarget.value);
  };

  const cleanFields = () => {
    setName("");
    setEmail("");
    setMessage("");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const res = contactValidation(name, email, message);
    setError(res);
    if (!res.email && !res.fields) {
      try {
        const contactInfo: Contact = {
          name: name,
          email: email,
          message: message,
        };
        const response = await contactCall(contactInfo);
        if (response.ok) {
          setOpen(true);
          cleanFields();
        } else {
          setError({
            email: "",
            fields: "",
            system: UNEXPECTED_ERROR,
          });
        }
      } catch (err) {
        setError({
          email: "",
          fields: "",
          system: CONNECTION_ERROR,
        });
      }
    }
  };

  return (
    <>
      <div
        id="Contact"
        className="md:flex items-center justify-center md:py-40 lg:p-40 sm:mx-12 max-sm:mb-36 sm:max-md:mb-96 md:space-x-2 h-screen"
      >
        <div className="md:inline-block md:w-1/2 max-md:pt-20 max-md:pb-10 max-md:p-5 max-h-[95vh]">
          <div className="pl-12">
            <Typography
              variant="h3"
              className="p-2 inline-block rounded-lg font-['system-ui']"
              style={{ color: "#333333" }}
            >
              {CONTACT_TITLE}
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <LocationOnOutlinedIcon
                    style={{ color: "rgb(71 85 105)", width: "1.7rem" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      style={{
                        color: "rgb(71 85 105)",
                        fontSize: "1.14rem",
                      }}
                    >
                      Cuareim 1447
                    </Typography>
                  }
                />
              </ListItem>
              {/* <ListItem>
                <ListItemIcon>
                  <LocalPhoneOutlinedIcon color="success" />
                </ListItemIcon>
                <ListItemText style={{ color: "green" }} primary="098390210 / 091204647" />
              </ListItem> */}
              <ListItem>
                <ListItemIcon>
                  <PhoneIphoneOutlinedIcon
                    style={{ color: "rgb(71 85 105)", width: "1.7rem" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      style={{
                        color: "rgb(71 85 105)",
                        fontSize: "1.14rem",
                      }}
                    >
                      098390210 / 091204647
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmailOutlinedIcon
                    style={{ color: "rgb(71 85 105)", width: "1.7rem" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      style={{
                        color: "rgb(71 85 105)",
                        fontSize: "1.14rem",
                      }}
                    >
                      ricechain.ort@gmail.com
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </div>
          <img
            className="hidden md:block mt-12 max-w-md w-[90%]"
            src="/Decoration.png"
            alt="decoration"
          />
        </div>
        <div className="md:inline-block md:w-1/2 max-h-[95vh] text-center">
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <div>
              <PrincipalFormField
                label="&nbsp;&nbsp;&nbsp;Nombre"
                onChange={handleNameChange}
                height="60px"
                width="90%"
                id="name-input"
                value={name}
                marginTop="10px"
                marginBottom="0px"
              />
            </div>
            <div>
              <PrincipalFormField
                label="&nbsp;&nbsp;&nbsp;Email"
                onChange={handleEmailChange}
                height="60px"
                width="90%"
                id="email-input"
                value={email}
                marginTop="10px"
                marginBottom="0px"
              />
            </div>
            <div style={{ marginTop: "0px" }}>
              <TextField
                id="outlined-multiline-static"
                label="&nbsp;&nbsp;&nbsp;Escriba su mensaje aquí..."
                value={message}
                multiline
                rows={4}
                onChange={handleMessageChange}
                margin="dense"
                variant="standard"
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused, &.MuiInputLabel-shrink": {
                      color: "gray",
                      marginTop: "5px",
                      marginLeft: "5px",
                    },
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    "& input": {
                      padding: "10px",
                      marginLeft: "5px",
                      marginTop: "0px",
                    },
                    marginLeft: "15px",
                  },
                }}
                className="w-[95vw] rounded-2xl"
                style={{
                  border: "1.5px solid rgb(71 85 105 / 30%)",
                  marginTop: "10px",
                  marginBottom: "0px",
                  width: "90%",
                }}
              />
            </div>
            {(error.email || error.fields || error.system) && (
              <div className="text-left">
                {Object.values(error)
                  .filter((err) => err !== "")
                  .map((err) => (
                    <Alert
                      key={err}
                      severity="error"
                      style={{
                        borderRadius: "100px",
                        marginBottom: "1rem",
                        color: "#d32f2f",
                        marginTop: "15px",
                      }}
                    >
                      {err}
                    </Alert>
                  ))}
              </div>
            )}
            <Button
              variant="contained"
              className="w-[90vw]"
              style={{
                background: "#76885B",
                borderRadius: "50px",
                height: "50px",
                marginTop: "25px",
                fontFamily: "system-ui",
                fontSize: "1.2rem",
                padding: "0.5rem 1rem",
                letterSpacing: "1px",
                display: "inline-block",
                whiteSpace: "nowrap",
                fontWeight: "300",
                textTransform: "none",
                boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
                width: "90%",
              }}
              color="success"
              type="submit"
            >
              {SEND_TEXT}
            </Button>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {MESSAGE_SENT}
              </Alert>
            </Snackbar>
          </form>
        </div>
      </div>
    </>
  );
};

export default CompanyContact;

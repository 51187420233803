import React from "react";
import { Field } from "../models/Field.model";
import FormTextfield from "../library/textfield/FormTextfield";
import FormSwitch from "../library/switch/FormSwitch";
import FormSelect from "../library/select/FormSelect";
import SingleSelectCheckbox from "../library/checkbox/SingleSelectCheckbox";
import CheckboxItem from "../library/checkbox/CheckboxItem";
import { FieldAnswer } from "../models/FieldAnswer.model";
import RCMap from "../library/map/Map";
import { Divider, Typography } from "@mui/material";
import classes from "./Field.module.css";

interface Props {
  field: Field;
  darkMode: boolean;
  disabled?: boolean;
  titleOnTop?: boolean;
  onChange: (event: FieldAnswer) => void;
}

const FieldComponent: React.FC<Props> = ({
  field,
  darkMode,
  disabled,
  titleOnTop,
  onChange,
}) => {
  const [selected, setSelected] = React.useState<string | undefined>(
    field.selected
  );

  const handleChange = (newVal: string) => {
    var newSelectedValue;
    if (field.type === "Select") {
      if (selected?.includes(newVal)) {
        const selectedArray = selected
          .split(",")
          .filter((value) => value !== newVal);
        newSelectedValue = selectedArray.join(",");
      } else {
        newSelectedValue = selected ? selected + "," + newVal : newVal;
      }
    } else {
      newSelectedValue = newVal;
    }
    setSelected(newSelectedValue);
    onChange({ fieldId: field.id, selected: newSelectedValue });
  };

  let renderForm;

  switch (field.type) {
    case "Boolean":
      renderForm = (
        <FormSwitch
          selected={selected}
          onChange={handleChange}
          disabled={disabled}
        />
      );
      break;
    case "Select":
      const columnWidth = "200px";
      const numColumns = Math.ceil(field.values ? field.values?.length / 8 : 0);
      renderForm = (
        <div
          className={classes.checkboxContainer}
          style={{
            gridTemplateColumns: `repeat(${numColumns}, ${columnWidth})`,
          }}
        >
          {field.values?.map((value) => (
            <CheckboxItem
              key={value}
              darkMode={darkMode}
              text={value}
              selected={selected?.includes(value)}
              onChange={handleChange}
              disabled={disabled}
            />
          ))}
        </div>
      );
      break;
    case "String":
      renderForm = (
        <FormTextfield
          adornment={field.placeHolder}
          onChange={handleChange}
          selected={selected}
          disabled={disabled}
        />
      );
      break;
    case "RadioButton":
      renderForm = (
        <SingleSelectCheckbox
          darkMode={darkMode}
          values={field.values}
          onChange={handleChange}
          selected={selected}
          disabled={disabled}
        />
      );
      break;
    case "Date":
      renderForm = (
        <input
          className="text-center w-4/5 h-16 bg-white text-black max-w-full font-['system-ui'] rounded-2xl text-[1.2rem] px-1.5 mt-4 sm:max-lg:min-w-48"
          style={{
            border: "1.5px solid rgb(71 85 105 / 30%)",
          }}
          disabled={disabled}
          value={selected ? selected : ""}
          type="date"
          name={field.name}
          onChange={(event) => handleChange(event.target.value)}
        />
      );
      break;
    case "Dropdown":
      renderForm = (
        <FormSelect
          text={field.name}
          options={field.values}
          onChange={handleChange}
          selected={selected}
          darkMode={darkMode}
          disabled={disabled}
        />
      );
      break;
    case "Map":
      let coordSplit = selected ?? undefined;
      renderForm = (
        <div
          className="mt-4 relative rounded-2xl overflow-hidden border-8 border-green-700	"
          style={{
            borderColor: "rgb(118, 136, 91)",
            borderRadius: "15px",
            borderWidth: "2px",
            marginBottom: "30px",
          }}
        >
          <RCMap
            value={coordSplit}
            disabled={disabled}
            onChange={handleChange}
          />
        </div>
      );
      break;
    default:
      renderForm = <p>El tipo de datos no existe</p>;
      break;
  }

  return (
    <div
      className={
        titleOnTop || field.type === "Map"
          ? "flex flex-col items-center justify-evenly w-80 sm:w-full"
          : "flex max-lg:space-x-6 items-center justify-evenly w-80 sm:w-full"
      }
      style={{ margin: "0px" }}
    >
      {/* eslint-disable-next-line eqeqeq */}
      {field.name && (field.type == "Select" || field.type == "Map" || titleOnTop) ? (
        <>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            style={{ color: "#282928", alignContent: "left" }}
          >
            {field.name}
          </Typography>
          <Divider style={{ margin: "16px 0", width: "70%" }} />
          <div style={{ marginTop: "1rem" }}>{renderForm}</div>
        </>
      ) : (
        <div className="mx-8 flex items-center justify-between md:justify-center w-full">
          <div className={field.name ? "w-[35%] text-left" : "hidden"}>
            <Typography
              variant="h5"
              align="left"
              gutterBottom
              className={classes.responsiveTitle}
            >
              {field.name}
            </Typography>
          </div>
          <div
            className={
              field.name ? "w-[55%] md:w-1/2 text-right" : "w-full md:w-1/2"
            }
          >
            <div style={{ marginTop: "1rem" }}>{renderForm}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FieldComponent;

import React from "react";
import MainNavigation from "./MainNavigation"; // Supongo que tienes un componente MainNavigation separado

const Header: React.FC<{
  token: any;
  handleLoginClick: () => void;
}> = ({ token, handleLoginClick }) => {
  return (
    <div id="Top" className="flex justify-between w-full mt-3.5 gap-2.5 p-5">
      <div className="flex w-1/2 justify-center ">
        <MainNavigation token={token} handleLoginClick={handleLoginClick} />
      </div>
    </div>
  );
};

export default Header;

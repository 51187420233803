import { InputAdornment, TextField } from "@mui/material";
import React from "react";

interface Props {
  adornment?: string;
  selected?: string;
  disabled?: boolean;
  onChange: (newVal: string) => void;
}

const FormTextfield: React.FC<Props> = ({
  adornment,
  selected,
  disabled,
  onChange
}) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <TextField
      variant="standard"
      InputLabelProps={{
        sx: {
          '&.Mui-focused, &.MuiInputLabel-shrink': {
            color: 'gray',
            marginTop: '5px',
            marginLeft: '5px',
          },
        },
      }}
      InputProps={{
        disableUnderline: true,
        sx: {
          '& input': {
            padding: '10px',
            marginLeft: '5px',
            marginTop: '10px',
          },
        },
        endAdornment: (
          <InputAdornment position="end" style={{ fontSize: "18px", paddingRight: "15px", paddingTop: "10px" }}>
            {adornment ? adornment : ""}
          </InputAdornment>
        ),
      }}
      className="w-full sm:w-4/5 bg-white rounded-2xl h-16 font-['sysem-ui'] mb-8"
      style={{
        border: '1.5px solid rgb(71 85 105 / 30%)',
      }}
      onChange={handleChange}
      value={selected ? selected : ""}
      disabled={disabled}
    />
  );
};

export default FormTextfield;

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { NO_PRODUCTION, PRODUCTIONS_AS } from "../utils/constants";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface Props {
  productions: [
    {
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      completedStagesShow: string;
      completedStages: number;
    }
  ];
  title: string;
}

const ProductionsList: React.FC<Props> = ({ productions, title }) => {
  const navigate = useNavigate();

  // const formatDateString = (dateString: string) => {
  //   const date = new Date(dateString);
  //   const day = date.getDate();
  //   const month = date.getMonth() + 1;
  //   const year = date.getFullYear();

  //   const formattedDay = day < 10 ? `0${day}` : day;
  //   const formattedMonth = month < 10 ? `0${month}` : month;

  //   return `${formattedDay}-${formattedMonth}-${year}`;
  // };

  const navigateToProduction = (id: string) => {
    if (title === "administrador") {
      localStorage.setItem("isAdmin", "true");
    } else {
      localStorage.setItem("isAdmin", "false");
    }
    navigate("/productions/" + id);
  };

  const navigateToCertificate = (id: string, name: string) => {
    navigate(`/certificate/productionId/${id}?productionName=${name}`);
  };

  const renderList =
    productions.length > 0 ? (
      <div style={{ display: "inline-block", width: "100%" }}>
        {productions.map((item) => (
          <Card
            key={item.id}
            variant="outlined"
            style={{
              borderRadius: 20,
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
              background: "#f5faf2",
              marginBottom: "15px",
            }}
          >
            <CardContent>
              <Grid container spacing={2} style={{ alignContent: "center" }}>
                <Grid
                  item
                  xs={8}
                  style={{ alignContent: "center", margin: "0" }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    style={{
                      fontFamily: "system-ui",
                      color: "#282928",
                      alignContent: "center",
                      margin: "0",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Divider
                    orientation="vertical"
                    style={{ height: "100%", margin: "0 auto" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    gutterBottom
                    align="center"
                    alignContent="center"
                    style={{ fontFamily: "system-ui" }}
                  >
                    {`Etapas: ${item.completedStagesShow}`}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (
                        item.completedStagesShow.split("/")[0] ===
                        item.completedStagesShow.split("/")[1]
                      ) {
                        navigateToCertificate(item.id, item.name);
                      } else {
                        navigateToProduction(item.id);
                      }
                    }}
                    style={{
                      color: "#76885B",
                      background: "rgb(237 244 232)",
                      borderRadius: "50px",
                      height: "50px",
                      fontFamily: "system-ui",
                      fontSize: "1.2rem",
                      padding: "0.5rem 1rem",
                      letterSpacing: "1px",
                      display: "flex",
                      alignItems: "center", // Alinea verticalmente el contenido
                      justifyContent: "center", // Alinea horizontalmente el contenido
                      whiteSpace: "nowrap",
                      fontWeight: "300",
                      textTransform: "none",
                      boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
                      width: "95%",
                      marginBottom: "5px",
                    }}
                  >
                    <ArrowForwardIcon style={{ marginRight: "5px" }} />{" "}
                    {/* Icono de flecha hacia adelante */}
                    {/* Texto alternativo */}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </div>
    ) : (
      <p
        style={{ marginTop: "35px", marginBottom: "35px", color: "#61695d" }}
        className="text-white text-3xl font-['system-ui']"
      >
        {NO_PRODUCTION}
      </p>
    );
  return (
    <div
      className="my-5 mx-auto max-w-2xl"
      style={{
        background: "#f2fff363",
        padding: "20px",
        borderRadius: "15px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 4px 3px",
      }}
    >
      <Typography
        variant="h3"
        style={{
          color: "#333333",
          borderRadius: "10px",
          display: "inline-block",
          padding: "0.5rem",
          fontFamily: "system-ui",
          fontSize: "170%",
          fontWeight: 400,
          letterSpacing: "1px",
          marginBottom: "30px",
        }}
      >
        {PRODUCTIONS_AS + title}
      </Typography>
      {renderList}
    </div>
  );
};

export default ProductionsList;

// UNA OPCION DE CARD

//     <Card
//       key={item.id}
//       variant="outlined"
//       style={{
//         borderRadius: 20,
//         boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
//         background: "#f5faf2",
//         marginTop: "-50px",
//         marginBottom: "60px",
//       }}
//     >
//       <CardContent>
//         <Grid container spacing={2}>
//           <Grid item xs={8}>
//             <Typography variant="h5" align="center" gutterBottom style={{ color: "#282928" }}>
//               {item.name}
//             </Typography>
//             <Typography variant="body1" gutterBottom>
//               {`Etapas: ${item.completedStagesShow}`}
//             </Typography>
//           </Grid>
//           <Grid item xs={1}>
//             <Divider orientation="vertical" style={{ height: "100%", margin: "0 auto" }} />
//           </Grid>
//           <Grid item xs={3} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
//           <Button
//               variant="contained"
//               color="primary"
//               onClick={() => {
//                 if (item.completedStagesShow.split("/")[0] === item.completedStagesShow.split("/")[1]) {
//                   navigateToCertificate(item.id, item.name);
//                 } else {
//                   navigateToProduction(item.id);
//                 }
//               }}
//               style={{
//                 color: "#76885B",
//                 background: "rgb(237 244 232)",
//                 borderRadius: "50px",
//                 height: "50px",
//                 fontFamily: "system-ui",
//                 fontSize: "1.2rem",
//                 padding: "0.5rem 1rem",
//                 letterSpacing: "1px",
//                 display: "flex",
//                 alignItems: "center", // Alinea verticalmente el contenido
//                 justifyContent: "center", // Alinea horizontalmente el contenido
//                 whiteSpace: "nowrap",
//                 fontWeight: "300",
//                 textTransform: "none",
//                 boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
//                 width: "95%",
//               }}
//             >
//               <ArrowForwardIcon style={{ marginRight: "5px" }} /> {/* Icono de flecha hacia adelante */}
//                {/* Texto alternativo */}
//                </Button>
//                </Grid>
//              </Grid>
//            </CardContent>
//          </Card>

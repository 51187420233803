import { Button } from "@mui/material";
import React from "react";
import classes from "./FormButton.module.css";

interface Props {
  darkMode: boolean;
  text: string;
  disabled?: boolean;
  onClick: () => void;
}

const FormButton: React.FC<Props> = ({ darkMode, text, disabled, onClick }) => {
  return (
    <Button
      variant="contained"
      type="submit"
      className={classes.btn}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default FormButton;

import { json } from "react-router-dom";
import { CONNECTION_ERROR } from "../utils/constants";
import { createProductionFlowCertification } from "../adapters/Certification.adapter";

export async function getCertificateCall(productionId: string | undefined) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/certification/stageForm?productionId=${productionId}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      return json(
        {
          message: `No existen datos para la producción con Id = ${productionId}`,
        },
        {
          status: 500,
        }
      );
    } else {
      const resData = await response.json();
      return createProductionFlowCertification(resData);
    }
  } catch (error) {
    return json({ error: CONNECTION_ERROR });
  }
}

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";

interface Props {
  text: string;
  options?: string[];
  selected?: string;
  darkMode: boolean;
  disabled?: boolean;
  onChange: (newVal: string) => void;
}

const FormSelect: React.FC<Props> = ({ text, options, selected, darkMode, disabled, onChange }) => {
  const [selection, setSelection] = useState(selected ? selected : "");

  const handleChange = (event: SelectChangeEvent) => {
    setSelection(event.target.value as string);
    onChange(event.target.value as string);
  };

  return (
    <FormControl
      fullWidth
      sx={{ minWidth: 150 }}
      style={{ marginTop: "0.5rem", width: "80%" }}
    >
      <InputLabel>{text}</InputLabel>
      <Select
        value={selection}
        label={text}
        onChange={handleChange}
        disabled={disabled}
        style={{
          border: '1.5px solid rgb(71 85 105 / 30%)',
          maxWidth: '80% !important',
          borderRadius: '16px',
          margin: '5px',
          height: '60px',
          fontFamily: "system-ui" ,
          textAlign: "left",
          minWidth: "13vw",
          paddingLeft: "5px",
          background: "white",
          backgroundColor: "white",
          color: "black"
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormSelect;

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Form,
  Link,
  useActionData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { signupValidation } from "../utils/AuthValidation";
import {
  REGISTERED_USER_TITLE,
  REGISTERED_USER_TEXT,
  REGISTERED_USER_BUTTON,
  ALREADY_HAVE_ACCOUNT,
  REGISTERING_TEXT,
  CONFIRM_TEXT,
} from "../utils/constants";
import PrincipalFormField from "./PrincipalFormField";

const SignupForm: React.FC = () => {
  const data: any = useActionData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({ name: "", email: "", password: "" });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data && !data.error) {
      setOpen(true);
    }
  }, [data, setOpen]);

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.currentTarget.value);
  };

  const handleLastNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLastName(event.currentTarget.value);
  };

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.currentTarget.value);
  };

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(event.currentTarget.value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setConfirmPassword(event.currentTarget.value);
  };

  const validateInputs = (event: SyntheticEvent) => {
    const res = signupValidation({
      name,
      lastName,
      email,
      password,
      confirmPassword,
    });
    setErrors(res);
    if (res.name || res.email || res.password) {
      event.preventDefault();
    }
  };

  const redirectToLogin = () => {
    navigate("/auth?mode=login");
  };

  return (
    <Form method="post" className="flex flex-col justify-center items-center">
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Nombre"
        onChange={handleNameChange}
        height="60px"
        width="95%" 
        id="firstName"
        name="firstName"
        marginTop="10px"
        marginBottom="0px"
      />
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Apellido"
        onChange={handleLastNameChange}
        height="60px"
        width="95%" 
        id="lastName"
        name="lastName"
        marginTop="10px"
        marginBottom="0px"
      />
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Email"
        onChange={handleEmailChange}
        height="60px"
        width="95%" 
        id="email"
        name="email"
        marginTop="10px"
        marginBottom="0px"
      />
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Contraseña"
        onChange={handlePasswordChange}
        height="60px"
        width="95%" 
        id="password"
        name="password"
        marginTop="10px"
        marginBottom="0px"
        type="password"
      />
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Confirmar contraseña"
        onChange={handleConfirmPasswordChange}
        height="60px"
        width="95%" 
        id="confirmPassword"
        name="confirmPassword"
        marginTop="10px"
        marginBottom="0px"
        type="password"
      />
      {(errors.name || errors.email || errors.password) && (
        <div className="text-left w-full" style={{marginTop: "15px"}}>
          {Object.values(errors)
            .filter((err) => err !== "")
            .map((err) => (
              <Alert key={err} severity="error" style={{borderRadius: "100px", marginBottom: "1rem", width: "95%", marginLeft: "2.5%", color: "#d32f2f"}}>
                {err}
              </Alert>
            ))}
        </div>
      )}
      {data && data.error && (
        <div className="text-left w-full" style={{marginTop: "15px"}}>
          <Alert severity="error" style={{borderRadius: "100px", marginBottom: "1rem", width: "95%", marginLeft: "2.5%", color: "#d32f2f"}}>{data.error}</Alert>
        </div>
      )}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{REGISTERED_USER_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText>{REGISTERED_USER_TEXT}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={redirectToLogin} autoFocus>
            {REGISTERED_USER_BUTTON}
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        className="w-full h-12"
        style={{
          background: "#76885B",
          borderRadius: "50px",
          height: "50px",
          marginTop: "30px",
          fontFamily: "system-ui",
          fontSize: "1.2rem",
          padding: "0.5rem 1rem",
          letterSpacing: "1px",
          display: "inline-block",
          whiteSpace: "nowrap",
          fontWeight: "300",
          textTransform: "none",
          boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
          width: "95%"
        }}
        color="success"
        type="submit"
        disabled={isSubmitting}
        onClick={validateInputs}
      >
        {isSubmitting ? REGISTERING_TEXT : CONFIRM_TEXT}
      </Button>
      <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
        <Typography
          sx={{
            color: "#232423",
            fontFamily: "system-ui",
            fontSize: "0.9rem",
            marginRight: "5px", 
          }}
        >
          {ALREADY_HAVE_ACCOUNT}
        </Typography>
        <Link
          to={"?mode=login"}
          className="no-underline text-blue-500"
          style={{ marginLeft: "5px", fontSize: "0.9rem" }}
        >
          {REGISTERED_USER_BUTTON}
        </Link>
      </div>
    </Form>
  );
};

export default SignupForm;

import React from "react";
import { Section } from "../models/Section.model";
import { Subsection } from "../models/Subsection.model";
import SubsectionComponent from "./Subsection";
import classes from "./Section.module.css";
import FormButton from "../library/button/FormButton";
import { SAVE_TEXT, SEND_TEXT } from "../utils/constants";
import { FieldAnswer } from "../models/FieldAnswer.model";

interface Props {
  section: Section;
  darkMode: boolean;
  isLastSec: boolean;
  Secindex: number;
  completed: boolean;
  onChange: (event: FieldAnswer) => void;
  onSubmit: () => void;
  onSave: () => void;
}

const SectionComponent: React.FC<Props> = ({
  section,
  darkMode,
  isLastSec,
  Secindex,
  completed,
  onChange,
  onSubmit,
  onSave,
}) => {
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  //const [formAnswers, setFormAnswers] = React.useState<FieldAnswer[]>([]);

  // const handleChange = (event: FieldAnswer[]) => {
  //   // map the field answer array and check if the fieldId is already present, if it is, replace it with the new value
  //   // if not, push the new value into the array
  //   const newFormAnswers = [...formAnswers];
  //   event.forEach((item) => {
  //     const index = newFormAnswers.findIndex((answer) => answer.fieldId === item.fieldId);
  //     if (index === -1) {
  //       newFormAnswers.push(item);
  //     } else {
  //       newFormAnswers[index] = item;
  //     }
  //   });
  //   setFormAnswers(event);
  // };

  return (
    <div className={darkMode ? classes.darkMode : classes.lightMode}>
      <div className={Secindex === 0 ? "pt-36 pb-24 px-20" : "p-20"}>
        {section.subsections.map((subsection: Subsection, index: number) => (
          <SubsectionComponent
            key={index}
            darkMode={darkMode}
            subsection={subsection}
            completed={completed}
            onChange={onChange}
          />
        ))}
        {isLastSec ? (
          <div className="flex justify-center space-x-5 md:space-x-10 mt-24">
            {!completed && (
              <FormButton
                darkMode={darkMode}
                text={SAVE_TEXT}
                onClick={onSave}
              />
            )}
            {isAdmin && !completed && (
              <FormButton
                darkMode={darkMode}
                text={SEND_TEXT}
                onClick={onSubmit}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SectionComponent;

export const BACK_HOME_BUTTON = 'Inicio';
export const GO_BACK_BUTTON = 'Volver';
export const AUTH_TITLE = 'PRODUCCIÓN';
export const LOGIN_TEXT = 'Ingresar';
export const LOGOUT_TEXT = 'Cerrar sesión';
export const LOADING_TEXT = 'Cargando...';
export const LOADING_UPPER_CASE = 'CARGANDO...';
export const REGISTERING_TEXT = "REGISTRANDO...";
export const LOGGING_IN = "Ingresando...";
export const ENTER_TEXT = 'Ingresar';
export const REGISTER_USER_TEXT = 'Registrar Usuario';
export const REGISTERED_USER_BUTTON = "Iniciar sesión";
export const REGISTER_TEXT = "Registrarme";
export const FORGOT_PASSWORD = "Olvide mi contraseña";
export const NEW_PRODUCTION_TEXT = 'Crear Producción';
export const SEND_TEXT = 'Enviar';
export const CONFIRM_TEXT = "Confirmar";
export const CONFIRM_PRODUCTION_TITLE = "Nueva producción creada";
export const CONFIRM_PRODUCTION_TEXT = "Tu nueva producción ha sido creada con éxito. Puedes comenzar a trabajar en ella.";
export const BEGIN_BUTTON = "Comenzar";
export const CONTENT_TITLE = 'Texto';
export const CONTENT_TEXT = "Bienvenidos a RiceChain, la innovación en trazabilidad y certificación del arroz en Uruguay. Utilizando la tecnología blockchain, aseguramos la calidad y transparencia desde la pre-siembra hasta la cosecha. RiceChain es más que un sistema: es una promesa de eficiencia y confianza, elevando el estándar del arroz uruguayo."; 
export const NOT_FOUND_TITLE = 'La pagina no existe!';
export const NOT_FOUND_TEXT = 'Vuelva a la pagina principal';
export const CONTACT_TITLE = 'Contactos';
export const MESSAGE_SENT = 'Mensaje enviado!';
export const REGISTERED_USER_TITLE = "Usuario Registrado!";
export const REGISTERED_USER_TEXT = "Hemos registrado con éxito tu cuenta. Por favor, inicia sesión.";
export const COPYRIGHT_TEXT = "© RiceChain 2023 Todos los derechos reservados";
export const HOME_BUTTON = "Inicio";
export const ABOUT_BUTTON = "Sobre Nosotros";
export const OUR_TEAM_BUTTON = "Nuestro Equipo";
export const CONTACT_BUTTON = "Contacto";
export const COMPANY_NAME = "RiceChain";
export const COMPANY_DESCRIPTION = "RiceChain nace de la unión entre la tecnología y la agricultura, impulsada por un equipo multidisciplinario dedicado a fortalecer la producción de arroz en Uruguay. Nuestra misión es doble: elevar la calidad del arroz uruguayo y ayudar a nuestros agricultores con herramientas eficientes y transparentes. Nos comprometemos con la calidad, la transparencia y la sostenibilidad.";
export const PRODUCTIONS_TITLE = "Producciones";
export const NEW_PRODUCTION_TITLE = "Nueva Producción";
export const PRODUCTION_NAME = "Nombre";
export const PRODUCTION_LAST_UPDATED = "Última actualización";
export const PRODUCTION_CREATED_AT = "Fecha de creación";
export const NO_PRODUCTION = "No hay producciones.";
export const PRODUCTIONS_AS = "Producciones como ";
export const CONFIRM_RESET_PASSWORD_TITLE = "Tu contraseña se ha actualizado!";
export const CONFIRM_RESET_PASSWORD_TEXT = "Hemos actualizado tu contraseña con éxito, inicie sesión con sus nuevos datos.";
export const CHECK_EMAIL = "Revisa tu correo electrónico";
export const CONNECTION_ERROR = "Error de conexión, intente de nuevo.";
export const UNEXPECTED_ERROR = "Error al enviar el mensaje";
export const ERROR_RESPONSE = "La acción no se pudo completar";
export const ADD_CONTRIBUTOR = "Añadir colaborador";
export const ADD_CONTRIBUTOR_TEXT = "Ingrese el correo electrónico del colaborador que desea agregar y su rol en la producción.";
export const CANCEL = "Cancelar";
export const ADD = "Añadir";
export const ROLE = "Rol";
export const CONTRIBUTOR_ROLE = "Colaborador";
export const ADMIN_ROLE = "Administrador";
export const START = "Acceder";
export const BACK_TO_STAGES = "Volver a etapas de producción";
export const STAGES_TITLE = "Etapas de Producción";
export const SAVE_TEXT = "Guardar";
export const SAVED_SUCCESSFULLY = "Guardado con éxito";
export const UNCOMMITED_CHANGES_TITLE = "CUIDADO!";
export const UNCOMMITED_CHANGES_TEXT = "Tienes cambios sin guardar. Deseas guardarlos antes de salir?";
export const DONT_SAVE = "Salir sin guardar";
export const IN_PROGRESS_STATUS = "IN PROGRESS";
export const COMPLETED_STATUS = "DONE";
export const NOT_STARTED_STATUS = "BLOCKED";
export const EDIT_TEXT = "Editar";
export const CONFIRM_SUBMIT_TITLE = "Confirmar envío";
export const CONFIRM_SUBMIT_TEXT = "Estás seguro que deseas enviar esta etapa? Esta acción no se puede deshacer.";
export const USER_SETTINGS_TITLE = "Configuración";
export const CERTIFICATE_SUBTITLE = 'Certificado de producción aprobada y guardada en la Blockchain';
export const QR_TEXT = 'Acceda a esta página escaneando el siguiente QR:';
export const COPY_TRANSACTION_ID_MESSAGE = 'Id de transacción copiado al portapapeles';
export const COPY_QR_MESSAGE = 'QR copiado al portapapeles';
export const CERTIFICATIONS_NOT_FOUND = 'No se encontraron certificaciones';
export const NOT_MEMBER_TEXT = '¿Nuevo en RiceChain?';
export const ALREADY_HAVE_ACCOUNT = '¿Ya tienes cuenta?';
export const ADD_PRODUCTION = 'Añadir nueva Producción';

import React from "react";
import {
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import copyToClipboard from "clipboard-copy";
import { COPY_TRANSACTION_ID_MESSAGE } from "../utils/constants";
import { StageForm } from "../models/StageForm.model";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import classes from "../pages/Certificate.module.css";

interface CertificationTableProps {
  stageForm: StageForm;
}

const CertificationTable: React.FC<CertificationTableProps> = (props) => {
  const { stageForm } = props;
  const [notificationOpen, setNotificationOpen] = React.useState(false);

  const tableStyle = {
    // border: "1px solid #ddd",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 3px 2px",
  };

  const tableContainerStyle = {
    width: "80%",
    margin: "auto",
  };

  const handleCopyToClipboard = () => {
    if (stageForm.transactionId) {
      copyToClipboard(stageForm.transactionId);
      openNotification();
    }
  };

  const openNotification = () => {
    setNotificationOpen(true);
  };

  const closeNotification = () => {
    setNotificationOpen(false);
  };

  return (
    <div style={tableContainerStyle}>
      <Typography className={classes.stageTitle}>{stageForm.name}</Typography>
      <div>
        <Typography className={classes.versionTitle}>
          Version: {stageForm.version}
        </Typography>

        <TableContainer component={Paper} style={tableStyle}>
          <Table>
            <TableBody>
              {stageForm.sections.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  {section.subsections.map((subsection, subsectionIndex) => {
                    return (
                      <React.Fragment key={subsectionIndex}>
                        {subsection.fields.map((field, fieldIndex) => (
                          <TableRow key={fieldIndex}>
                            {fieldIndex === 0 && (
                              <TableCell
                                rowSpan={subsection.fields.length}
                                className={classes.cellStyle}
                              >
                                {subsection.name === null ||
                                subsection.name === ""
                                  ? "-"
                                  : subsection.name}
                              </TableCell>
                            )}
                            <TableCell className={classes.cellStyle}>
                              {field.name === "" ? "-" : field.name}
                            </TableCell>
                            <TableCell className={classes.cellStyle}>
                              {field.selected === "" ? "-" : field.selected}
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="body1" className={classes.transaction}>
          Id de transacción: {stageForm.transactionId}
          <IconButton
            onClick={handleCopyToClipboard}
            style={{ padding: "3px 0px 8px 8px" }}
          >
            <ContentCopyIcon
              color="action"
              fontSize="small"
              style={{ width: "1.7vw" }}
            />
          </IconButton>
        </Typography>
      </div>
      <div style={{ marginTop: "50px", marginBottom: "20px" }}>
        <Divider />
      </div>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={closeNotification}
      >
        <MuiAlert elevation={6} severity="success" onClose={closeNotification}>
          {COPY_TRANSACTION_ID_MESSAGE}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CertificationTable;

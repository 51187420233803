import React from 'react';

interface InfoProps {
  contentText: string;
}

const Info: React.FC<InfoProps> = ({ contentText }) => {
  return (
    <div className="flex justify-center items-center h-screen px-8 pb-20">
      <img src="/Rice.PNG" alt="Rice" className="max-h-[40vw] sm:max-h-[25vw] md:p-4" />
      <div className="max-w-[60%] text-center inline-block">
        <p className="text-wrap text-sm md:text-base lg:text-lg xl:text-xl system-ui font-light">{contentText}</p>
      </div>
    </div>
  );
};

export default Info;

import React, { useEffect, useState } from "react";
import { Section } from "../models/Section.model";
import SectionComponent from "./Section";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useLocation,
  useNavigate,
  useParams,
  useSubmit,
} from "react-router-dom";
import {
  BACK_TO_STAGES,
  CANCEL,
  CONFIRM_SUBMIT_TEXT,
  CONFIRM_SUBMIT_TITLE,
  DONT_SAVE,
  ERROR_RESPONSE,
  HOME_BUTTON,
  LOGOUT_TEXT,
  PRODUCTIONS_TITLE,
  SAVED_SUCCESSFULLY,
  SAVE_TEXT,
  SEND_TEXT,
  UNCOMMITED_CHANGES_TEXT,
  UNCOMMITED_CHANGES_TITLE,
  USER_SETTINGS_TITLE,
} from "../utils/constants";
import { FieldAnswer } from "../models/FieldAnswer.model";
import { getAuthToken } from "../utils/Auth";
import { postAnswers } from "../services/Form.service";
import { FormAnswers } from "../models/FormAnswers.model";
import { AccountCircle } from "@mui/icons-material";
import ProductionForm from "./ProductionForm";
import classes from "./ProductionsNavigation.module.css";
import { Form as FormType } from "../models/Form.model";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "./Footer";

interface Props {
  form: FormType;
  logo: string;
}

const FormComponent: React.FC<Props> = ({ form, logo }) => {
  const [formAnswers, setFormAnswers] = useState<FieldAnswer[]>([]);
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false); // Estado para controlar la apertura del modal
  const submit = useSubmit();

  useEffect(() => {
    const newFormAnswers: FieldAnswer[] = [];
    form.sections.forEach((section) => {
      section.subsections.forEach((subsection) => {
        subsection.fields.forEach((field) => {
          newFormAnswers.push({
            fieldId: field.id,
            selected: field.selected ? field.selected : "",
          });
        });
      });
    });
    setFormAnswers(newFormAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function handleCloseConfirmDialog() {
    setOpenConfirmDialog(false);
  }

  function handleBackClick() {
    setOpenDialog(!isSaved);
    if (isSaved) {
      navigateToProductionPage();
    }
  }

  function navigateToProductionPage() {
    navigate(`/productions/${id}`);
  }

  const handleChange = (event: FieldAnswer) => {
    const newFormAnswers = [...formAnswers];
    const index = newFormAnswers.findIndex(
      (answer) => answer.fieldId === event.fieldId
    );
    if (index === -1) {
      newFormAnswers.push(event);
    } else {
      newFormAnswers[index] = event;
    }
    setFormAnswers(newFormAnswers);
    setIsSaved(false);
  };

  const onSubmit = async () => {
    const token = getAuthToken();
    const formAnswersBody: FormAnswers = {
      name: form.name,
      version: form.version,
      answers: formAnswers,
      productionId: id ? id : "",
    };
    const response = await postAnswers(token, formAnswersBody, "submit");
    setOpen(true);
    if (!response.ok) {
      setError(true);
    } else {
      setError(false);
      setIsSaved(true);
      return navigate(`/productions/${id}`);
    }
  };

  const onSave = async () => {
    const token = getAuthToken();
    var formAnswersBody: FormAnswers = {
      name: form.name,
      version: form.version,
      answers: formAnswers,
      productionId: id ? id : "",
    };
    const response = await postAnswers(token, formAnswersBody, "save");
    if (!response.ok) {
      setError(true);
    } else {
      setError(false);
      setOpenDialog(false);
      setIsSaved(true);
    }
    setOpen(true);
  };

  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountClose = async () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    setAnchorEl(null);
    submit(null, { action: "/logout", method: "post" });
    navigate("/");
  };

  const getEmail = () => {
    const token = localStorage.getItem("token");
    let email = "";
    if (token) {
      const tokenPayload = token.split(".")[1]; // Obtenemos la segunda parte del token (la que contiene la información)
      const decodedToken = JSON.parse(atob(tokenPayload)); // Decodificamos la información en formato base64 y la convertimos a JSON
      email = decodedToken?.user?.email; // Extraemos el email del objeto decodificado
    }
    return email;
  };

  const getFullName = () => {
    const token = localStorage.getItem("token");
    let firstName = "";
    let lastName = "";
    if (token) {
      const tokenPayload = token.split(".")[1]; // Obtenemos la segunda parte del token (la que contiene la información)
      const decodedToken = JSON.parse(atob(tokenPayload)); // Decodificamos la información en formato base64 y la convertimos a JSON
      firstName = decodedToken?.user?.firstName; // Extraemos el email del objeto decodificado
      lastName = decodedToken?.user?.lastName; // Extraemos el email del objeto decodificado
    }
    return firstName + " " + lastName;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function navigateUserSettings() {
    navigate("/user-settings");
  }

  const handleProductionsClick = async () => {
    setAnchorEl(null);
    navigate("/productions");
  };

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* Nuevo Header */}
      <header
        className="fixed top-0 left-0 right-0 z-10 flex justify-between items-center p-8 shadow-md"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px",
          marginBottom: "20px",
          padding: "1rem",
          background: "rgb(237, 244, 232)",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="flex w-1/4 justify-center items-center">
            <Tooltip title={BACK_TO_STAGES} placement="right">
              <img
                src={`/${logo}`}
                alt="Logo"
                className="max-w-40 h-auto cursor-pointer w-[80%]"
                onClick={handleBackClick}
              />
            </Tooltip>
          </div>
          <Typography
            variant="h5"
            style={{ color: "#333333", fontFamily: "system-ui" }}
          >
            Etapa: {form.name}
          </Typography>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
        >
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginRight: "20px", height: "40px" }}
          />
        </div>

        <IconButton
          aria-label="account"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleAccountClick}
          className={classes.accountButton}
          style={{ color: "#333333" }}
        >
          <AccountCircle fontSize="large" />
        </IconButton>

        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleAccountClose}
          PaperProps={{
            style: {
              backgroundColor: "rgb(245, 250, 242)",
              margin: "0px 0 0 -20px",
              fontFamily: "system-ui",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/");
            }}
          >
            {HOME_BUTTON}
          </MenuItem>

          <MenuItem onClick={handleProductionsClick}>
            {PRODUCTIONS_TITLE}
          </MenuItem>
          <MenuItem onClick={navigateUserSettings}>
            {USER_SETTINGS_TITLE}
          </MenuItem>
          <MenuItem onClick={handleSignOut}>{LOGOUT_TEXT}</MenuItem>
          <Divider />
          <Typography
            style={{
              fontFamily: "system-ui",
              margin: "5px 15px",
              fontSize: "14px",
              fontStyle: "italic",
            }}
          >
            {getFullName() + " - " + getEmail()}
          </Typography>
        </Menu>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="production-form-modal-title"
          aria-describedby="production-form-modal-description"
          // BackdropComponent={CustomBackdrop}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Paper
              elevation={3}
              style={{
                padding: "40px 30px",
                backgroundColor: "rgb(245, 250, 242)",
                width: "400px",
                borderRadius: "15px",
              }}
            >
              <ProductionForm />
            </Paper>
          </div>
        </Modal>
      </header>
      <div>
        {form.sections.map((section: Section, index: number) => (
          <SectionComponent
            key={index}
            darkMode={index % 2 !== 0}
            section={section}
            isLastSec={index === form.sections.length - 1}
            Secindex={index}
            onChange={handleChange}
            onSubmit={() => setOpenConfirmDialog(true)}
            onSave={onSave}
            completed={status?.toLocaleUpperCase() === "DONE"}
          />
        ))}
        <Footer />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error ? ERROR_RESPONSE : SAVED_SUCCESSFULLY}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {UNCOMMITED_CHANGES_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {UNCOMMITED_CHANGES_TEXT}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={navigateToProductionPage}>{DONT_SAVE}</Button>
          <Button
            onClick={() => {
              onSave();
              navigate(`/productions/${id}`);
            }}
            autoFocus
          >
            {SAVE_TEXT}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {CONFIRM_SUBMIT_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {CONFIRM_SUBMIT_TEXT}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false);
            }}
          >
            {CANCEL}
          </Button>
          <Button onClick={onSubmit} autoFocus>
            {SEND_TEXT}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Boton para ir al final de la pantalla */}
      <div
        style={{
          position: "fixed",
          bottom: "4%",
          left: "2.3%",
          zIndex: 1000,
        }}
      >
        <Tooltip title="Ir al fondo de la pantalla" arrow>
          <IconButton
            onClick={handleScrollToBottom}
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0 0 5px 2px",
              border: "solid 0px",
              borderColor: "lightgray",
              color: "#fff",
              background: "rgb(118, 136, 91)",
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

export default FormComponent;

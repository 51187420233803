import React, { ChangeEvent } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface PrincipalFormFieldProps extends Omit<TextFieldProps, 'onChange'> {
  id: string;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  height?: string;
  width?: string;
  type?: string;
  value?: string;
  marginTop?: string;
  marginBottom?: string;
  disabled?: any;
}

const PrincipalFormField: React.FC<PrincipalFormFieldProps> = ({
  id,
  label,
  onChange,
  name,
  height = '60px',
  width = '80%',
  type = 'text',
  value,
  marginTop = '15px',
  marginBottom = '30px',
  disabled,
  ...otherProps
}) => {
  return (
    <TextField
      id={id}
      name={name}
      label={label}
      variant="standard"
      type={type}
      InputLabelProps={{
        sx: {
          '&.Mui-focused, &.MuiInputLabel-shrink': {
            color: 'gray',
            marginTop: '5px',
            marginLeft: '5px',
          },
        },
      }}
      InputProps={{
        disableUnderline: true,
        sx: {
          '& input': {
            padding: '10px',
            marginLeft: '5px',
            marginTop: '0px',
          },
        },
      }}
      onChange={onChange}
      className="w-full bg-white rounded-2xl"
      style={{
        border: '1.5px solid rgb(71 85 105 / 30%)',
        width: width || '80%',
        borderRadius: '16px',
        marginTop: marginTop,
        marginBottom: marginBottom,
        height: height || '60px',
        fontFamily: "system-ui"
      }}
      value={value}
      disabled={disabled || false}
      {...otherProps}
    />
  );
};

export default PrincipalFormField;

import { Contact } from "../models/Contact.model";

export async function contactCall(contactInfo: Contact) {
    const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/home/contact`,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(contactInfo),
        }
      );
      return response;
}
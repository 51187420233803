import { Box, Container, IconButton, Typography } from "@mui/material";
//import TwitterIcon from "@mui/icons-material/Twitter";
//import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
//import YouTubeIcon from "@mui/icons-material/YouTube";
import React from "react";
import { COPYRIGHT_TEXT } from "../utils/constants";

const Footer: React.FC = () => {
  return (
    <Container
      className="flex justify-between items-center bg-black text-white py-2"
      style={{ maxWidth: "100%", display: "flex", background:"#191a19" }}
    >
      <Box className="w-1/2">
        <Typography variant="body2" color="white" align="left">
          {COPYRIGHT_TEXT}
        </Typography>
      </Box>
      <Box>
        {/* <IconButton
          className="w/1/4"
          style={{ backgroundColor: "#ffffff", margin: "2px" }}
          href=""
        >
          <FacebookIcon style={{ color: "black" }} />
        </IconButton>
        <IconButton
          className="w/1/4"
          style={{ backgroundColor: "#ffffff", margin: "2px" }}
          href=""
        >
          <TwitterIcon style={{ color: "black" }} />
        </IconButton> */}
        <IconButton
          className="w/1/4"
          style={{ backgroundColor: "#f0f0f0", margin: "2px" }}
          href="https://www.linkedin.com/company/ricechain/about/"
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          size="small" 
        >
          <LinkedInIcon style={{ color: "black" }} />
        </IconButton>
        {/* <IconButton
          className="w/1/4"
          style={{ backgroundColor: "#ffffff", margin: "2px" }}
          href=""
        >
          <YouTubeIcon style={{ color: "black" }} />
        </IconButton> */}
      </Box>
    </Container>
  );
};

export default Footer;

import React from "react";
import classes from "./StageCard.module.css";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Link } from "react-router-dom";
import {
  COMPLETED_STATUS,
  IN_PROGRESS_STATUS,
  NOT_STARTED_STATUS,
} from "../utils/constants";

interface Props {
  stage: {
    stageForm: {
      name: string;
      version?: string;
      stageStatus: string;
      logo: string;
    };
  };
}

const StageCard: React.FC<Props> = ({ stage }) => {
  let status;
  let style;
  switch (stage.stageForm.stageStatus.toLocaleUpperCase()) {
    case IN_PROGRESS_STATUS:
      status = "Activo";
      style = classes.activeHeader;
      break;
    case COMPLETED_STATUS:
      status = "Completado";
      style = classes.completedHeader;
      break;
    case NOT_STARTED_STATUS:
      status = "Pendiente";
      style = classes.inactiveHeader;
      break;
    default:
      return;
  }
  return (
    <Link
      to={`${stage.stageForm.name}?status=${stage.stageForm.stageStatus}`}
      onClick={() => localStorage.setItem("logo", stage.stageForm.logo)}
      className="no-underline w-1/2 min-w-80"
      style={{
        pointerEvents:
          stage.stageForm.stageStatus.toLocaleUpperCase() !== NOT_STARTED_STATUS
            ? "auto"
            : "none",
      }}
    >
      <Card
        className="flex flex-col justify-center items-center w-full border-[2px] border-solid border-[#ccc]"
        style={{
          opacity:
            stage.stageForm.stageStatus.toLocaleUpperCase() ===
            NOT_STARTED_STATUS
              ? 0.5
              : 1,
          backgroundColor: "transparent",
          borderRadius: "15px",
          maxWidth: "270px",
          maxHeight: "270px",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 4px 3px",
          margin: "1rem",
          borderWidth: "1px"
        }}
      >
        <CardHeader className={style} title={status} />
        <CardContent className="flex w-full justify-center items-center bg-transparent">
          <div className="mx-2.5 text-white">
            <h1 className="text-3xl font-['system-ui']" style={{fontSize: "27px", color: "black"}}>{stage.stageForm.name}</h1>
          </div>
          <div>
            <img
              className="w-60"
              src={`/${stage.stageForm.logo}`}
              alt="stage"
              style={{
                height: "123px",
                marginTop: "10px"
              }}
            />
          </div>
        </CardContent>
      </Card>
    </Link>
  );
};

export default StageCard;

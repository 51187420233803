export const signupValidation = (props: {
  name: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}) => {
  const errors: {
    name: string;
    email: string;
    password: string;
  } = { name: "", email: "", password: "" };

  if (props.name.trim().length === 0 || props.lastName.trim().length === 0) {
    errors.name = "Ingrese nombre y apellido";
  }

  errors.email = validateEmail(props.email);
  errors.password = validatePassword(props.password, props.confirmPassword);

  return errors;
};

export const loginValidation = (email: string, password: string) => {
  const errors: {
    email: string;
    password: string;
  } = { email: "", password: "" };

  errors.email = validateEmail(email);

  if (password.trim().length === 0) {
    errors.password = "Introduce una contraseña";
  }
  return errors;
};

export const contactValidation = (
  name: string,
  email: string,
  message: string
) => {
  const errors: {
    email: string;
    fields: string;
    system: string;
  } = { email: "", fields: "", system: "" };

  if (email) {
    errors.email = validateEmail(email);
  }
  if (!email || !name || !message) {
    errors.fields = "Complete todos los campos";
  }
  return errors;
};

export const resetPassValidation = (props: {
  isReset: boolean;
  email: string;
  password: string;
  confirmPassword: string;
}) => {
  const errors: {
    email: string;
    password: string;
  } = { email: "", password: "" };

  if (!props.isReset) {
    errors.email = validateEmail(props.email);
  } else {
    errors.password = validatePassword(props.password, props.confirmPassword);
  }

  return errors;
};

export const validateEmail = (email: string) => {
  if (!/\S+@\S+\.\S+/.test(email)) {
    return "El formato del email no es valido";
  }
  return "";
};

const validatePassword = (password: string, confirmPassword?: string) => {
  if (password.trim().length < 12) {
    return "La contraseña debe tener al menos 12 caracteres";
  } else if (password !== confirmPassword) {
    return "Las contraseñas no coinciden";
  }
  return "";
}

import React, { useEffect } from "react";
import { Subsection } from "../models/Subsection.model";
import { Field } from "../models/Field.model";
import SectionTitle from "../library/titles/SectionTitle";
import FieldComponent from "./Field";
import { FieldAnswer } from "../models/FieldAnswer.model";

interface Props {
  darkMode: boolean;
  subsection: Subsection;
  completed: boolean;
  onChange: (event: FieldAnswer) => void;
}

const SubsectionComponent: React.FC<Props> = ({
  subsection,
  darkMode,
  completed,
  onChange,
}) => {
  //const [valuesArray, setValuesArray] = useState<{fieldId: string, selected: string}[]>([]);

  // const handleChange = (event: FieldAnswer) => {
  // const newValuesArray = [...valuesArray];
  // const index = newValuesArray.findIndex((item) => item.fieldId === event.fieldId);
  // if (index === -1) {
  //   newValuesArray.push(event);
  // } else {
  //   newValuesArray[index] = event;
  // }
  // setValuesArray(newValuesArray);
  // onChange(newValuesArray);
  // }
  const [allSelectFields, setAllSelectFields] = React.useState<boolean>(false);

  useEffect(() => {
    const ret = subsection.fields.every(
      (field: Field) => field.type === "Select" || field.type === "Date");
    setAllSelectFields(ret);
  }, [subsection.fields]);

  return (
    <div className="flex max-lg:flex-col items-center justify-evenly">
      {subsection.name && (
        <SectionTitle
          isTitle={true}
          darkMode={darkMode}
          text={subsection.name}
          />
      )}
      <div
        className={
          allSelectFields
            ? "flex max-lg:flex-wrap items-baseline space-y-16 my-14 w-80 sm:max-lg:w-[80vw] lg:w-2/3 py-4 lg:px-8 md:py-16"
            : "flex max-lg:flex-wrap flex-col items-center space-y-16 my-14 w-80 sm:max-lg:w-[80vw] lg:w-2/3 py-4 lg:px-8 md:py-16"
        }
        style={{ borderRadius: "15px", borderWidth: "1px", borderColor: "lightgray", margin: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 2px", background: "rgb(245, 250, 242)", height: "100%"}}
        // style={{ padding: "60px 30px", borderRadius: "15px", borderWidth: "1px", borderColor: "lightgray", margin: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 2px", background: "#E1F0DA"}}
      >
        {subsection.fields.map((field: Field, index: number) => (
          <FieldComponent
            key={index}
            darkMode={darkMode}
            field={field}
            disabled={completed}
            onChange={onChange}
            titleOnTop={allSelectFields}
          />
        ))}
      </div>
    </div>
  );
};

export default SubsectionComponent;

import React from "react";
import { json } from "react-router-dom";
import AuthForm from "../components/AuthForm";
import { User } from "../models/User.model";
import { authenticationCall } from "../services/Authentication.service";
import Footer from "../components/Footer";

const AuthPage: React.FC = () => {
  // const navigate = useNavigate();

  // function handleHomeClick() {
  //   navigate("/");
  // }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        className="bg-lime-100 min-h-screen overflow-auto relative"
        style={{ flex: 1, paddingTop: "5.5rem", background: "#D7E4C0" }}
      >
        {/* <div className="justify-start items-center">
          <Button
            onClick={handleHomeClick}
            style={{
              color: "#232423",
              backgroundColor: "rgba(199, 253, 158, 0.959)",
              margin: "45px 20px 20px 45px",
              paddingRight: "15px",
              textTransform: "none"
            }}
            startIcon={<ChevronLeftIcon />}
          >
            {BACK_HOME_BUTTON}
          </Button>
        </div> */}
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center mt-5 w-full">
            <AuthForm />
          </div>
        </div>
        <div
          className="flex items-end justify-end min-h-20"
          style={{ marginTop: "1vh" }}
        >
          <img
            src="Leaves.png"
            alt="Leaves"
            className="w-1/4 mr-12 mb-4 min-w-60"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AuthPage;

export async function action({ request }: any) {
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get("mode") || "login";

  if (mode !== "login" && mode !== "signup") {
    throw json({ message: "Unsupported mode." }, { status: 422 });
  }

  const data = await request.formData();
  var authData: User = {
    email: data.get("email"),
    password: data.get("password"),
  };

  if (mode === "signup") {
    authData.firstName = data.get("firstName");
    authData.lastName = data.get("lastName");
  }

  return await authenticationCall(mode, authData);
}

import React, { useState } from "react";
import { NavLink, useNavigate, useSubmit } from "react-router-dom";
import classes from "./ProductionsNavigation.module.css";
import {
  ADD_PRODUCTION,
  HOME_BUTTON,
  LOGOUT_TEXT,
  USER_SETTINGS_TITLE,
} from "../utils/constants";
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddCircle, AccountCircle } from "@mui/icons-material";
import ProductionForm from "./ProductionForm";

const ProductionsNavigation: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false); // Estado para controlar la apertura del modal
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const submit = useSubmit();
  // Obtener el token del localStorage

  const handleClose2 = async () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleAddProductionClick = () => {
    setOpen(true);
  };

  const getEmail = () => {
    const token = localStorage.getItem("token");
    let email = "";
    if (token) {
      const tokenPayload = token.split(".")[1]; // Obtenemos la segunda parte del token (la que contiene la información)
      const decodedToken = JSON.parse(atob(tokenPayload)); // Decodificamos la información en formato base64 y la convertimos a JSON
      email = decodedToken?.user?.email; // Extraemos el email del objeto decodificado
    }
    return email;
  };

  const getFullName = () => {
    const token = localStorage.getItem("token");
    let firstName = "";
    let lastName = "";
    if (token) {
      const tokenPayload = token.split(".")[1]; // Obtenemos la segunda parte del token (la que contiene la información)
      const decodedToken = JSON.parse(atob(tokenPayload)); // Decodificamos la información en formato base64 y la convertimos a JSON
      firstName = decodedToken?.user?.firstName; // Extraemos el email del objeto decodificado
      lastName = decodedToken?.user?.lastName; // Extraemos el email del objeto decodificado
    }
    return firstName + " " + lastName;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };

  function navigateUserSettings() {
    navigate("/user-settings");
  }

  const handleSignOut = async () => {
    setAnchorEl(null);
    submit(null, { action: "/logout", method: "post" });
    navigate("/");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <header
      className="fixed top-0 left-0 right-0 z-10 flex justify-between items-center p-8 shadow-md"
      style={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
        padding: "1rem",
        background: "rgb(237, 244, 232)",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Volver a Inicio"} placement="bottom">
          <div className="flex w-1/4 justify-center items-center">
            <NavLink to="/">
              <img
                src="Leaves.png"
                alt="Logo"
                className="max-w-96 cursor-pointer w-full md:w-[80%]"
              />
            </NavLink>
          </div>
        </Tooltip>
        <Typography
          variant="h5"
          style={{
            color: "#333333",
            fontFamily: "system-ui",
            fontSize: "1.5rem",
          }}
        >
          Producciones
        </Typography>
      </div>

      <div className="flex items-center ml-auto hidden md:flex">
        <Button
          variant="contained"
          color="success"
          onClick={handleAddProductionClick}
          startIcon={<AddCircle />}
          className={classes.addProdBtn}
        >
          {ADD_PRODUCTION}
        </Button>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginRight: "20px", height: "40px" }}
        />
      </div>

      <div className="flex items-center ml-auto md:hidden">
        <IconButton
          onClick={handleAddProductionClick}
          className={classes.addProdBtn2}
        >
          <AddCircle />
        </IconButton>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginRight: "20px", height: "40px" }}
        />
      </div>

      <Dialog open={open} onClose={handleClose2}>
        <div
          style={{
            backgroundColor: "rgb(245, 250, 242)",
          }}
          className="px-7 py-10 rounded-2xl w-80 sm:w-[30rem]"
        >
          <ProductionForm />
        </div>
      </Dialog>

      <IconButton
        aria-label="account"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.accountButton}
        style={{ color: "#333333" }}
      >
        <AccountCircle fontSize="large" />
      </IconButton>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "rgb(245, 250, 242)",
            margin: "0px 0 0 -20px",
            fontFamily: "system-ui",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/");
          }}
        >
          {HOME_BUTTON}
        </MenuItem>
        <MenuItem onClick={navigateUserSettings}>
          {USER_SETTINGS_TITLE}
        </MenuItem>
        <MenuItem onClick={handleSignOut}>{LOGOUT_TEXT}</MenuItem>
        <Divider />
        <Typography
          style={{
            fontFamily: "system-ui",
            margin: "5px 15px",
            fontSize: "14px",
            fontStyle: "italic",
          }}
        >
          {getFullName() + " - " + getEmail()}
        </Typography>
      </Menu>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="production-form-modal-title"
        aria-describedby="production-form-modal-description"
        // BackdropComponent={CustomBackdrop}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: "40px 30px",
              backgroundColor: "rgb(245, 250, 242)",
              width: "400px",
              borderRadius: "15px",
            }}
          >
            <ProductionForm />
          </Paper>
        </div>
      </Modal>
    </header>
  );
};
// const CustomBackdrop = (props: any) => {
//   return <Backdrop {...props} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />;
// };
export default ProductionsNavigation;

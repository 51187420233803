import React, { FormEvent, useState } from "react";
import {
  Form,
  useActionData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { getAuthToken } from "../utils/Auth";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import classes from "./ProductionForm.module.css";
import {
  BEGIN_BUTTON,
  CONFIRM_PRODUCTION_TEXT,
  CONFIRM_PRODUCTION_TITLE,
  LOADING_TEXT,
  NEW_PRODUCTION_TEXT,
} from "../utils/constants";
import {
  createProductionCall,
  createProductionFlowCall,
} from "../services/Productions.service";
import PrincipalFormField from "./PrincipalFormField";
import { Production } from "../models/Production.model";

const ProductionForm: React.FC = () => {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  let data: any = useActionData();
  const navigate = useNavigate();
  const token = getAuthToken();

  const [name, setName] = useState("");
  const [id, setId] = useState<any>();
  const [open, setOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = React.useState(false);

  const openNotification = () => {
    setNotificationOpen(true);
  };

  const closeNotification = () => {
    setNotificationOpen(false);
  };

  // useEffect(() => {
  //   if (error && !error.error) {
  //     setOpen(true);
  //   }
  // }, [error, setOpen]);

  // const handleClose = (
  //   event?: React.SyntheticEvent | Event,
  //   reason?: string
  // ) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.currentTarget.value);
  };

  const redirectToProduction = async () => {
    await createProductionFlowCall(token, id.toString(), name);
    localStorage.setItem("isAdmin", "true");
    navigate("/productions/" + id);
  };

  function waitOneSecond() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000); // Espera 1000 milisegundos (1 segundo)
    });
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Evitar que el formulario se envíe automáticamente

    data = new FormData(event.currentTarget);
    
  const productionData: Production = {
    name: data.get("name"),
  };
      openNotification();
      await waitOneSecond();
      setOpen(true);
  const token = getAuthToken();
  const productionResponse: any = await createProductionCall(token, productionData);
  setId(productionResponse.id);

    // event.preventDefault(); // Prevenimos el comportamiento por defecto del formulario
    // const authToken = getAuthToken();
    // try {
    //   productionData = { name }; // Obtener los datos del formulario
    //   await createProductionCall(authToken, productionData); // Llamar a la función para crear la producción
    //   openNotification();
    //   await waitOneSecond();
    //   setOpen(true);
    //   // redirectToProduction();
    // } catch (error) {
    //   console.error("Error al enviar el formulario:", error);
    //   // Manejar el error, por ejemplo, mostrar un mensaje al usuario
    // }
  };

  return (
    <>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        style={{ color: "#282928" }}
      >
        {NEW_PRODUCTION_TEXT}
      </Typography>
      <Divider style={{ margin: "16px 0" }} />
      <Form
        onSubmit={handleSubmit}
        style={{ marginTop: "5%" }}
        className={classes.form}
      >
        <PrincipalFormField
          label="&nbsp;&nbsp;&nbsp;Nombre"
          onChange={handleNameChange}
          height="60px"
          width="95%"
          id="name"
          name="name"
          marginTop="10px"
          marginBottom="0px"
        />
        <Button
          variant="contained"
          className={classes.button}
          style={{
            background: "#76885B",
            borderRadius: "50px",
            height: "50px",
            marginTop: "25px",
            fontFamily: "system-ui",
            fontSize: "1.2rem",
            padding: "0.5rem 1rem",
            letterSpacing: "1px",
            display: "inline-block",
            whiteSpace: "nowrap",
            fontWeight: "300",
            textTransform: "none",
            boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
            width: "95%",
          }}
          color="success"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? LOADING_TEXT : "Crear"}
        </Button>
      </Form>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{CONFIRM_PRODUCTION_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText>{CONFIRM_PRODUCTION_TEXT}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={redirectToProduction} autoFocus>
            {BEGIN_BUTTON}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={closeNotification}
      >
        <Alert elevation={6} severity="success" onClose={closeNotification}>
          Producción creada correctamente
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductionForm;

export async function action({ request }: any) {
  const data = await request.formData();

  const productionData: Production = {
    name: data.get("name"),
  };

  const token = getAuthToken();
  return await createProductionCall(token, productionData);
}

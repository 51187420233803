import FormComponent from "../components/Form";
import React, { Suspense, useEffect, useState } from "react";
import { getAuthToken } from "../utils/Auth";
import { Await, defer, useLoaderData } from "react-router-dom";
import { getStageFields } from "../services/Form.service";
import { createForm } from "../adapters/Form.adapter";
import { Form } from "../models/Form.model";
import { LOADING_TEXT } from "../utils/constants";

const FormRoot: React.FC = () => {
  const { data }: any = useLoaderData();
  const form: Form = createForm(data);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    setLogo(localStorage.getItem("logo") ?? "");
  }, []);

  return (
    <Suspense fallback={<div>{LOADING_TEXT}</div>}>
      <Await resolve={form}>
        <FormComponent form={form} logo={logo} />
      </Await>
    </Suspense>
  );
};

export default FormRoot;

async function loadFields(name: string, productionId: string) {
  const token = getAuthToken();
  return await getStageFields(token, name, productionId);
}

export async function loader({ request, params }: any) {
  const name = params.stageName;
  const productionId = params.id;
  return defer({
    data: await loadFields(name, productionId),
  });
}

import { json } from "react-router-dom";
import { CONNECTION_ERROR } from "../utils/constants";

export async function updateUserDataCall(token: string | null, userId: string, userData: any) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
      {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      }
    );

    if (!response.ok) {
      return json({
        error: "No se pudo actualizar la información del usuario.",
      });
    }

    return "ok";
  } catch (error) {
    return json({ error: CONNECTION_ERROR });
  }
}
import React from "react";
import ResetPassForm from "../components/ResetPassForm";
import Footer from "../components/Footer";
import { json } from "react-router-dom";
import { User } from "../models/User.model";
import { resetPasswordCall } from "../services/Authentication.service";

const ResetPasssword: React.FC = () => {
  // const navigate = useNavigate();

  // const handleBackClick = () => {
  //   navigate("/auth");
  // };

  // const handleHomeClick = () => {
  //   navigate("/");
  // };

  return (
    <>
      <div
        className="bg-lime-100 h-screen"
        style={{ background: "rgb(215, 228, 192)" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <img
            src="/Logo.PNG"
            alt="Logo"
            onClick={handleHomeClick}
            style={{
              marginRight: "3vw",
              height: "auto",
              maxWidth: "180px",
              cursor: "pointer",
            }}
          /> */}
        </div>
        <div
          style={{
            height: "53%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "8rem",
          }}
        >
          <ResetPassForm />
        </div>
        <div
          className=" bottom-0 right-0 mb-4 mr-4"
          style={{ marginTop: "3rem", marginRight: "3rem" }}
        >
          <img
            src="Leaves.png"
            alt="Leaves"
            className="w-1/4 min-w-60"
            style={{ float: "right" }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetPasssword;

export async function action({ request }: any) {
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get("mode") || "forgot";

  if (mode !== "forgot" && mode !== "reset") {
    throw json({ message: "Unsupported mode." }, { status: 422 });
  }

  const data = await request.formData();
  var authData: User = {};

  if (mode === "forgot") {
    authData.email = data.get("email");
  } else {
    authData.resetPasswordToken = searchParams.get("token") || undefined;
    authData.password = data.get("password");
  }

  return await resetPasswordCall(request.method, mode, authData);
}

import { ProductionFlowCertification } from "../models/Certification.model";


export function createProductionFlowCertification(obj: any): ProductionFlowCertification {
    const productionFlowCertificationModel: ProductionFlowCertification = {
        productionId: obj.productionId ?? undefined,
        stageForms: obj.stageForms ?? undefined,
    };

    return productionFlowCertificationModel;
}

import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/Home";
import RootLayout from "./pages/Root";
import NotFound from "./pages/NotFound";
import React from "react";
import AuthPage, { action as authAction } from "./pages/Authentication";
import ResetPasssword, {
  action as resetPassAction,
} from "./pages/ResetPassword";
import { action as logoutAction } from "./pages/Logout";
import {
  tokenLoader,
  checkAuthLoader,
  checkIfUserIsLogged,
} from "./utils/Auth";
import ProductionsRoot from "./pages/ProductionsRoot";
import ProductionsPage, {
  loader as productionsLoader,
} from "./pages/Productions";
import { action as newProductionAction } from "./components/ProductionForm";
import FormRoot, { loader as formLoader } from "./pages/FormRoot";
import ProductionPage, { loader as stagesLoader } from "./pages/Production";
import UserSettingsPage, {
  action as userSettingsAction,
} from "./pages/UserSettingsPage";
import CertificatePage, {
  loader as certificationLoader,
} from "./pages/Certificate";
import NewProductionPage from "./pages/NewProduction";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <NotFound />,
    id: "root",
    loader: tokenLoader,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "auth",
        element: <AuthPage />,
        action: authAction,
        loader: checkIfUserIsLogged,
      },
      {
        path: "reset_password",
        element: <ResetPasssword />,
        action: resetPassAction,
      },
      { path: "logout", action: logoutAction },
      {
        path: "user-settings",
        element: <UserSettingsPage />,
        action: userSettingsAction,
      },
      {
        path: "productions",
        element: <ProductionsRoot />,
        loader: checkAuthLoader,
        children: [
          {
            index: true,
            element: <ProductionsPage />,
            loader: productionsLoader,
          },
          {
            path: "new",
            element: <NewProductionPage />,
            action: newProductionAction,
          },
          {
            path: ":id/:stageName",
            element: <FormRoot />,
            loader: formLoader,
          },
          {
            path: ":id",
            element: <ProductionPage />,
            loader: stagesLoader,
          },
        ],
      },
      {
        path: "certificate/productionId/:productionId",
        element: <CertificatePage />,
        loader: certificationLoader,
      },
    ],
  },
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;

import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import ProductionsList from "../components/ProductionsList";
import { getAuthToken } from "../utils/Auth";
import { LOADING_TEXT } from "../utils/constants";
import { getProductionsCall } from "../services/Productions.service";
import ProductionsNavigation from "../components/ProductionsNavigation";
import { Grid } from "@mui/material";
import Footer from "../components/Footer";

const ProductionsPage: React.FC = () => {
  const { adminProductions, collabProductions }: any = useLoaderData();
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [openModal, setOpenModal] = useState(false); // Estado para controlar la apertura del modal

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = async () => {
  //   setAnchorEl(null);
  // };

  // const handleOpenModal = () => {
  //   setOpenModal(true);
  //   handleClose(); // Cierra el menú al abrir el modal
  // };

  // const handleCloseModal = () => {
  //   setOpenModal(false);
  // };
  return (
    <div>
      <ProductionsNavigation />
      <div className="mx-[8%] mt-[25%] sm:max-lg:mt-[15%] lg:mt-[8%]">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Suspense fallback={<div>{LOADING_TEXT}</div>}>
              <Await resolve={adminProductions}>
                {(productions) => (
                  <div className="w-full">
                    <ProductionsList
                      productions={productions}
                      title="administrador"
                    />
                  </div>
                )}
              </Await>
            </Suspense>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Suspense fallback={<div>{LOADING_TEXT}</div>}>
              <Await resolve={collabProductions}>
                {(productions) => (
                  <div className="w-full">
                    <ProductionsList
                      productions={productions}
                      title="colaborador"
                    />
                  </div>
                )}
              </Await>
            </Suspense>
          </Grid>
        </Grid>
      </div>
      {(adminProductions.length > 4 || collabProductions.length > 4) && <Footer/>}
    </div>
  );
};

export default ProductionsPage;

async function loadAdminProductions() {
  const token = getAuthToken();
  return await getProductionsCall(token, "admin");
}

async function loadCollabProductions() {
  const token = getAuthToken();
  return await getProductionsCall(token, "editor");
}

export async function loader() {
  return defer({
    adminProductions: await loadAdminProductions(),
    collabProductions: await loadCollabProductions(),
  });
}

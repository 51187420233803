import { Alert, Button, Typography } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import { Form, Link, useActionData, useNavigation } from "react-router-dom";
import { loginValidation } from "../utils/AuthValidation";
import {
  FORGOT_PASSWORD,
  REGISTER_TEXT,
  LOGGING_IN,
  ENTER_TEXT,
  NOT_MEMBER_TEXT,
} from "../utils/constants";
import PrincipalFormField from "./PrincipalFormField";

const LoginForm: React.FC = () => {
  const data: any = useActionData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.currentTarget.value);
  };

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(event.currentTarget.value);
  };

  const validateInputs = (event: SyntheticEvent) => {
    const res = loginValidation(email, password);
    setErrors(res);
    if (res.email || res.password) {
      event.preventDefault();
    }
  };

  return (
    <Form method="post" className="flex flex-col justify-center items-center">
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Nombre o email"
        onChange={handleEmailChange}
        height="60px"
        width="95%" 
        id="email"
        name="email"
        marginTop="10px"
        marginBottom="0px"
      />
      <PrincipalFormField
        label="&nbsp;&nbsp;&nbsp;Contraseña"
        onChange={handlePasswordChange}
        height="60px"
        width="95%" 
        id="password"
        name="password"
        type="password"
        marginTop="10px"
        marginBottom="15px"
      />
      {(errors.email || errors.password) && (
        <div className="text-left w-full">
          {Object.values(errors)
            .filter((err) => err !== "")
            .map((err) => (
              <Alert key={err} severity="error" style={{borderRadius: "100px", marginBottom: "1rem", width: "95%", marginLeft: "2.5%", color: "#d32f2f"}}>
                {err}
              </Alert>
            ))}
        </div>
      )}
      {data && data.error && (
        <div className="text-left w-full">
          <Alert severity="error" style={{borderRadius: "100px", marginBottom: "1rem", width: "95%", marginLeft: "2.5%", color: "#d32f2f"}}>{data.error}</Alert>
        </div>
      )}
      <Link
        to="/reset_password"
        className="self-start no-underline text-red-500"
        style={{fontSize: "0.9rem", marginLeft: "5%" }}
      >
        {FORGOT_PASSWORD}
      </Link>
      <Button
        variant="contained"
        className="w-full h-12"
        style={{
          background: "#76885B",
          borderRadius: "50px",
          height: "50px",
          marginTop: "25px",
          fontFamily: "system-ui",
          fontSize: "1.2rem",
          padding: "0.5rem 1rem",
          letterSpacing: "1px",
          display: "inline-block",
          whiteSpace: "nowrap",
          fontWeight: "300",
          textTransform: "none",
          boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
          width: "95%"
        }}
        onClick={validateInputs}
        disabled={isSubmitting}
        color="success"
        type="submit"
      >
        {isSubmitting ? LOGGING_IN : ENTER_TEXT}
      </Button>
      <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
        <Typography
          sx={{
            color: "#232423",
            fontFamily: "system-ui",
            fontSize: "0.9rem",
            marginRight: "5px", 
          }}
        >
          {NOT_MEMBER_TEXT}
        </Typography>
        <Link
          to={"?mode=signup"}
          className="no-underline text-blue-500"
          style={{ marginLeft: "5px", fontSize: "0.9rem" }} // Espacio entre "RiceChain?" y el enlace
        >
          {REGISTER_TEXT}
        </Link>
      </div>
    </Form>
  );
};

export default LoginForm;

import React from 'react';
import './PrincipalButton.css'; 
import { Button } from '@mui/material';

interface PrincipalButtonProps {
  href: string;
  children: React.ReactNode; 
}

const PrincipalButton: React.FC<PrincipalButtonProps> = ({ href, children }) => {
  return (
    <Button className="principalButton" href={href}>
      {children}
    </Button>
  );
};

export default PrincipalButton;

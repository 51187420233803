import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface PrincipalSelectFieldProps {
  id: string;
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  marginTop?: string;
  marginBottom?: string;
}

const PrincipalSelectField: React.FC<PrincipalSelectFieldProps> = ({
  id,
  label,
  value,
  onChange,
  marginTop = '0px',
  marginBottom = '30px',
}) => {
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      style={{
        marginTop: "0px",
        marginBottom: marginBottom,
        width: '95%',
        color: 'gray',
        borderRadius: '16px',
        height: '60px',
        // border: '1.5px solid rgba(71, 85, 105, 0.3)',
        background: 'white',
        fontFamily: "system-ui"
      }}
      displayEmpty // Esto es necesario para mostrar el elemento MenuItem vacío cuando no hay un valor seleccionado
    >
      <MenuItem value="Administrador">Administrador</MenuItem>
      <MenuItem value="Colaborador">Colaborador</MenuItem>
    </Select>
  );
};

export default PrincipalSelectField;

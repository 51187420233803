import { Grid } from "@mui/material";
import classes from "./CompanyInformation.module.css";
import MemberProfile from "./MemberProfile";
import React from "react";
import { COMPANY_NAME, COMPANY_DESCRIPTION } from "../utils/constants";

const CompanyInformation: React.FC = () => {
  return (
    <>
      <div
        id="Information"
        className={`${classes.informationContainer} flex justify-center items-center`}
        style={{ paddingTop: "4vw" }} // Ajusta el padding superior usando vw
      >
        <div className="inline-block text-white sm:flex justify-center items-center md:w-4/5 text-center">
          <div className="w-full">
            <div className="sm:p-5">
              <h2 className="font-['system-ui'] text-4xl md:text-6xl">
                {COMPANY_NAME}
              </h2>
              <p className="font-light font-['system-ui'] text-sm md:text-xl leading-6 md:leading-[2.2vw]">
                {COMPANY_DESCRIPTION}
              </p>
            </div>
          </div>
          <div className="w-full">
            <img
              src={"inelagro.png"}
              alt="Imagen"
              className="inline-block w-full h-[50%] p-5 text-white my-[4vw] sm:my-[8%] align-middle max-w-[1/4] rounded-3xl" // Ajusta el tamaño de la imagen y el margen superior
            />
          </div>
        </div>
      </div>
      <div id="Team" className={classes.membersContainer}>
        <Grid container className="w-full" spacing={5}>
          <Grid item xs={12} sm={6} md={3}>
            <MemberProfile
              img={"MartinImg.png"}
              name={"Martín Gonzalez"}
              position={"CEO"}
              linkedin={
                "https://www.linkedin.com/in/martin-gonzalez-echeverria-867230209/"
              }
              //twitter={""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MemberProfile
              img={"IsaiasImg.png"}
              name={"Isaías Gonzalez"}
              position={"CEO"}
              linkedin={"https://www.linkedin.com/in/isaiasgonzalezecheverria/"}
              //twitter={""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MemberProfile
              img={"EstebanImg.png"}
              name={"Esteban Montes de Oca"}
              position={"CEO"}
              linkedin={"https://www.linkedin.com/in/esteban-montes-de-oca/"}
              //twitter={""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MemberProfile
              img={"IgnacioImg.png"}
              name={"José Ignacio Viganó"}
              position={"CEO"}
              linkedin={
                "https://www.linkedin.com/in/ignacio-vigan%C3%B3-abb917205/"
              }
              //twitter={""}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CompanyInformation;

import {
  Alert,
  Button,
  Card,
  CardContent,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Form,
  useActionData,
  useNavigation,
} from "react-router-dom";
import {
  CONFIRM_TEXT,
  EDIT_TEXT,
  ERROR_RESPONSE,
  LOADING_UPPER_CASE,
  SAVED_SUCCESSFULLY,
} from "../utils/constants";
import CertificationNavigation from "./CertificationNavigation";
import PrincipalFormField from "./PrincipalFormField";

interface Props {
  lsname: string;
  lslastName: string;
}

const UserSettings: React.FC<Props> = ({ lsname, lslastName }) => {
  const data: any = useActionData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  const [name, setName] = useState(lsname);
  const [lastName, setLastName] = useState(lslastName);
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (data && !data.error) {
      setOpen(true);
      setEditable(false);
    }
  }, [data, setOpen]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.currentTarget.value);
  };

  const handleLastNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLastName(event.currentTarget.value);
  };

  return (
    <div
      className="bg-lime-100 min-h-screen overflow-auto relative"
      style={{ background: "rgb(245, 250, 242)" }}
    >
      <CertificationNavigation title={"Configuración de usuario"} />
      <div
        className="flex flex-col items-center justify-center"
        style={{ marginTop: "100px", width: "100%" }}
      >
        <div
          className="flex items-center justify-center mt-5 w-full"
          style={{ width: "80%" }}
        >
          <Card
            variant="outlined"
            style={{
              width: "100%",
              maxWidth: 400,
              borderRadius: 20,
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
              background: "#f5faf2",
              marginTop: "0px",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                align="center"
                gutterBottom
                style={{ color: "#282928" }}
              >
                Gestión de usuario
              </Typography>
              <Divider style={{ margin: "16px 0" }} />

              <Form
                method="post"
                className="flex flex-col justify-center items-center"
                style={{ width: "100%" }}
              >
                <PrincipalFormField
                  label="&nbsp;&nbsp;&nbsp;Nombre"
                  onChange={handleNameChange}
                  height="60px"
                  width="95%"
                  id="firstName"
                  name="firstName"
                  marginTop="10px"
                  marginBottom="0px"
                  value={name}
                  disabled={!editable}
                />
                <PrincipalFormField
                  label="&nbsp;&nbsp;&nbsp;Apellido"
                  onChange={handleLastNameChange}
                  height="60px"
                  width="95%"
                  id="lastName"
                  name="lastName"
                  marginTop="10px"
                  marginBottom="0px"
                  value={lastName}
                  disabled={!editable}
                />
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity={data && data.error ? "error" : "success"}
                    sx={{ width: "100%" }}
                  >
                    {data && data.error ? ERROR_RESPONSE : SAVED_SUCCESSFULLY}
                  </Alert>
                </Snackbar>
                  <Button
                    variant="contained"
                    className="w-full h-12"
                    style={{
                      background: "#76885B",
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "30px",
                      fontFamily: "system-ui",
                      fontSize: "1.2rem",
                      padding: "0.5rem 1rem",
                      letterSpacing: "1px",
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      fontWeight: "300",
                      textTransform: "none",
                      boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
                      width: "95%",
                    }}
                    color="success"
                    disabled={isSubmitting || editable}
                    onClick={() => setEditable(true)}
                  >
                    {EDIT_TEXT}
                  </Button>
                  <Button
                    variant="contained"
                    className="w-full h-12"
                    style={{
                      background: "#76885B",
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "30px",
                      fontFamily: "system-ui",
                      fontSize: "1.2rem",
                      padding: "0.5rem 1rem",
                      letterSpacing: "1px",
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      fontWeight: "300",
                      textTransform: "none",
                      boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
                      width: "95%",
                    }}
                    color="success"
                    type="submit"
                    disabled={isSubmitting || !name || !lastName || !editable}
                  >
                    {isSubmitting ? LOADING_UPPER_CASE : CONFIRM_TEXT}
                  </Button>
              </Form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;

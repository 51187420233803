import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import {
  HOME_BUTTON,
  ABOUT_BUTTON,
  OUR_TEAM_BUTTON,
  CONTACT_BUTTON,
} from '../utils/constants';
import PrincipalButton from './PrincipalButton';

const MainNavigation: React.FC<{
  token: any;
  handleLoginClick: () => void;
}> = ({ token, handleLoginClick }) => {
  return (
    <AppBar
      style={{
        backgroundColor: 'white',
        width: '95%',
        left: '0',
        margin: '0 auto',
        top: 'auto',
        borderRadius: '50px',
      }}
      position="fixed"
    >
      <Toolbar style={{ width: '100%' }}>
        <div
          id="1"
          className="justify-center items-center block rounded-2xl ml-5 mb-0.5 sm:w-[8%] md:w-[15%]"
        >
          <img
            src="/Logo.PNG"
            alt="Logo"
            className="sm:max-lg:max-w-full max-w-[70%] w-auto"
          />
        </div>

        <div id="2" className="w-4/5 ml-[14%]">
          <div className="justify-center items-center hidden sm:block rounded-2xl">
            <PrincipalButton href="#Top">{HOME_BUTTON}</PrincipalButton>
            <PrincipalButton href="#Information">
              {ABOUT_BUTTON}
            </PrincipalButton>
            <PrincipalButton href="#Team">{OUR_TEAM_BUTTON}</PrincipalButton>
            <PrincipalButton href="#Contact">{CONTACT_BUTTON}</PrincipalButton>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default MainNavigation;

import {
  Backdrop,
  Button,
  Dialog,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  Form,
  NavLink,
  useNavigate,
  useNavigation,
  useParams,
  useSubmit,
} from "react-router-dom";
import {
  ADD_CONTRIBUTOR,
  HOME_BUTTON,
  LOADING_TEXT,
  LOGOUT_TEXT,
  PRODUCTIONS_TITLE,
  STAGES_TITLE,
  UNEXPECTED_ERROR,
  USER_SETTINGS_TITLE,
} from "../utils/constants";
import { validateEmail } from "../utils/AuthValidation";
import { getAuthToken } from "../utils/Auth";
import { Member } from "../models/Member.model";
import { Response } from "../models/Response.model";
import { addMemberCall } from "../services/Productions.service";
import { AccountCircle, AddCircle } from "@mui/icons-material";
import ProductionForm from "./ProductionForm";
import classes from "./ProductionsNavigation.module.css";
import PrincipalFormField from "./PrincipalFormField";
import PrincipalSelectField from "./PrincipalSelectField";

const ProductionHeader: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [role, setRole] = React.useState("editor");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const token = getAuthToken();
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false); // Estado para controlar la apertura del modal
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const submit = useSubmit();
  console.log(error);

  // Función para manejar el cambio en el dropdown
  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    setRole(event.target.value);
  };

  const getEmail = () => {
    const token = localStorage.getItem("token");
    let email = "";
    if (token) {
      const tokenPayload = token.split(".")[1]; // Obtenemos la segunda parte del token (la que contiene la información)
      const decodedToken = JSON.parse(atob(tokenPayload)); // Decodificamos la información en formato base64 y la convertimos a JSON
      email = decodedToken?.user?.email; // Extraemos el email del objeto decodificado
    }
    return email;
  };

  const getFullName = () => {
    const token = localStorage.getItem("token");
    let firstName = "";
    let lastName = "";
    if (token) {
      const tokenPayload = token.split(".")[1]; // Obtenemos la segunda parte del token (la que contiene la información)
      const decodedToken = JSON.parse(atob(tokenPayload)); // Decodificamos la información en formato base64 y la convertimos a JSON
      firstName = decodedToken?.user?.firstName; // Extraemos el email del objeto decodificado
      lastName = decodedToken?.user?.lastName; // Extraemos el email del objeto decodificado
    }
    return firstName + " " + lastName;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    setAnchorEl(null);
    submit(null, { action: "/logout", method: "post" });
    navigate("/");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  function navigateUserSettings() {
    navigate("/user-settings");
  }

  const handleAddContributorClick = () => {
    setOpen(true);
  };

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.currentTarget.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const res = validateEmail(email);
    setError(res);
    if (!res) {
      try {
        let formattedRole = "";
        // eslint-disable-next-line eqeqeq
        if (role == "Colaborador") {
          formattedRole = "editor";
          // eslint-disable-next-line eqeqeq
        } else if (role == "Administrador") {
          formattedRole = "admin";
        }
        var body: Member = {
          email: email,
          role: formattedRole,
        };
        const response = await addMemberCall(token, id, body);
        if (response.ok) {
          setOpen(false);
          setEmail("");
          setError("");
        } else {
          const error: Response = await response.json();
          setError(error.errorMessage);
        }
      } catch (e) {
        setError(UNEXPECTED_ERROR);
      }
    }
  };

  const handleProductionsClick = async () => {
    setAnchorEl(null);
    navigate("/productions");
  };

  return (
    <header
      className="fixed top-0 left-0 right-0 z-10 flex justify-between items-center p-8 shadow-md"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px",
        marginBottom: "20px",
        padding: "1rem",
        background: "rgb(237, 244, 232)",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Volver a Producciones"} placement="bottom">
          <div className="flex w-1/3 sm:w-1/4 justify-center items-center">
            <NavLink to="/productions">
              <img
                src="../Leaves.png"
                alt="Logo"
                className="max-w-96 cursor-pointer w-full sm:w-[80%]"
              />
            </NavLink>
          </div>
        </Tooltip>
        <Typography variant="h5" className={classes.headerTitle}>{STAGES_TITLE}</Typography>
      </div>
      <div className="flex items-center ml-auto hidden md:flex">
        <Button
          variant="contained"
          color="success"
          onClick={handleAddContributorClick}
          startIcon={<AddCircle />}
          style={{
            borderRadius: "50px",
            fontFamily: "system-ui",
            textTransform: "none",
            background: "rgb(118, 136, 91)",
            border: "solid",
            borderWidth: "0.5px",
            borderColor: "rgb(118, 136, 71",
            marginRight: "20px",
          }}
        >
          {ADD_CONTRIBUTOR}
        </Button>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginRight: "20px", height: "40px" }}
        />
      </div>

      <div className="flex items-center ml-auto md:hidden">
        <IconButton
          onClick={handleAddContributorClick}
          className={classes.addProdBtn2}
        >
          <AddCircle />
        </IconButton>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginRight: "20px", height: "40px" }}
        />
      </div>

      <IconButton
        aria-label="account"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.accountButton}
        style={{ color: "#333333" }}
      >
        <AccountCircle fontSize="large" />
      </IconButton>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "rgb(245, 250, 242)",
            margin: "0px 0 0 -20px",
            fontFamily: "system-ui",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/");
          }}
        >
          {HOME_BUTTON}
        </MenuItem>

        <MenuItem onClick={handleProductionsClick}>
          {PRODUCTIONS_TITLE}
        </MenuItem>
        <MenuItem onClick={navigateUserSettings}>
          {USER_SETTINGS_TITLE}
        </MenuItem>
        <MenuItem onClick={handleSignOut}>{LOGOUT_TEXT}</MenuItem>
        <Divider />
        <Typography
          style={{
            fontFamily: "system-ui",
            margin: "5px 15px",
            fontSize: "14px",
            fontStyle: "italic",
          }}
        >
          {getFullName() + " - " + getEmail()}
        </Typography>
      </Menu>

      <Dialog open={open} onClose={handleClose}>
        <div
          style={{
            backgroundColor: "rgb(245, 250, 242)",
          }}
          className="px-7 py-10 rounded-2xl w-80 sm:w-[30rem]"
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            style={{ color: "#282928" }}
          >
            {ADD_CONTRIBUTOR}
          </Typography>
          <Divider style={{ margin: "16px 0" }} />
          <Form
            onSubmit={handleSubmit}
            style={{ marginTop: "5%" }}
            className={classes.form}
          >
            <PrincipalFormField
              label="&nbsp;&nbsp;&nbsp;Email"
              onChange={handleEmailChange}
              height="60px"
              width="95%"
              id="email"
              name="email"
              marginTop="0px"
              marginBottom="0px"
            />
            <div>
              <Typography
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  marginBottom: "0px",
                  fontFamily: "system-ui",
                  color: "rgba(0, 0, 0, 0.67)",
                  fontSize: "15px",
                }}
              >
                Role
              </Typography>
              <PrincipalSelectField
                id="role-select"
                label="Role"
                value={role}
                onChange={handleRoleChange}
                marginTop="10px"
                marginBottom="20px"
              />
            </div>
            <Button
              variant="contained"
              className={classes.button}
              style={{
                background: "#76885B",
                borderRadius: "50px",
                height: "50px",
                marginTop: "25px",
                fontFamily: "system-ui",
                fontSize: "1.2rem",
                padding: "0.5rem 1rem",
                letterSpacing: "1px",
                display: "inline-block",
                whiteSpace: "nowrap",
                fontWeight: "300",
                textTransform: "none",
                boxShadow: "-2px 2px 6px rgba(0, 0, 0, 0.1)",
                width: "95%",
              }}
              color="success"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? LOADING_TEXT : "Crear"}
            </Button>
          </Form>
        </div>

        {/* <DialogTitle style={{ fontFamily: "system-ui", fontSize: "1.9vw", background: "rgb(245, 250, 242)" }}>
          {ADD_CONTRIBUTOR}
        </DialogTitle>
        <DialogContent  style={{background: "rgb(245, 250, 242)" }}>
          <DialogContentText style={{ fontFamily: "system-ui", fontSize: "1.5vw", background: "rgb(245, 250, 242)"  }}>{ADD_CONTRIBUTOR_TEXT}</DialogContentText>
          <TextField
            autoFocus
            id="email-input"
            label="&nbsp;&nbsp;&nbsp;Correo Electrónico"
            type="email"
            fullWidth
            variant="standard"
            style={{ marginBottom: "1rem" }}
            onChange={handleEmailChange}
            error={!!error}
            helperText={error}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{ROLE}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label={ROLE}
              onChange={handleChange}
            >
              <MenuItem value={"admin"}>{ADMIN_ROLE}</MenuItem>
              <MenuItem value={"editor"}>{CONTRIBUTOR_ROLE}</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions style={{background: "rgb(245, 250, 242)" }}>
          <Button onClick={handleClose} style={{ fontFamily: "system-ui", fontSize: "1.5vw" }}>{CANCEL}</Button>
          <Button onClick={handleSubmit} style={{ fontFamily: "system-ui", fontSize: "1.5vw" }}>{ADD}</Button>
        </DialogActions> */}
      </Dialog>
      <Backdrop
        sx={{ zIndex: 2000 }}
        open={openDrawer}
        onClick={handleDrawerClose} // Cierra el Drawer cuando se hace clic en el fondo
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="production-form-modal-title"
        aria-describedby="production-form-modal-description"
        // BackdropComponent={CustomBackdrop}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: "40px 30px",
              backgroundColor: "rgb(245, 250, 242)",
              width: "400px",
              borderRadius: "15px",
            }}
          >
            <ProductionForm />
          </Paper>
        </div>
      </Modal>
    </header>
    // <>
    //   <div className="flex justify-between items-center w-full">
    //     <div className="flex justify-center items-center">
    //       <Button
    //         style={{
    //           color: "black",
    //           backgroundColor: "rgba(199, 253, 158, 0.959)",
    //           margin: "45px 0 45px 45px",
    //           fontSize: "1.3vw",
    //           fontFamily: "Teko",
    //         }}
    //         onClick={handleBackClick}
    //         startIcon={<ChevronLeftIcon />}
    //         variant="contained"
    //       >
    //         {GO_BACK_BUTTON}
    //       </Button>
    //       {localStorage.getItem("isAdmin") === "true" && (
    //         <Button
    //           style={{
    //             color: "white",
    //             margin: "45px 0 45px 45px",
    //             fontFamily: "Teko",
    //             fontSize: "larger",
    //           }}
    //           onClick={handleAddContributorClick}
    //           color="success"
    //           variant="contained"
    //           startIcon={<AddBoxOutlinedIcon />}
    //         >
    //           {ADD_CONTRIBUTOR}
    //         </Button>
    //       )}
    //     </div>
    //     <div className="pr-8 mr-8">
    //       <IconButton
    //         aria-label="open drawer"
    //         edge="end"
    //         onClick={handleDrawerOpen}
    //         size="large"
    //         sx={{
    //           "&:hover": {
    //             backgroundColor: "transparent", // Establece el fondo a transparente en hover
    //           },
    //         }}
    //       >
    //         <MenuIcon style={{ color: "white" }} />
    //       </IconButton>
    //     </div>
    //   </div>
    //   <Typography
    //     className="text-center text-white"
    //     variant="h3"
    //     style={{ marginTop: "auto", fontFamily: "Teko" }}
    //   >
    //     {STAGES_TITLE}
    //   </Typography>
    //   <Dialog open={open} onClose={handleClose}>
    //     <DialogTitle style={{ fontFamily: "Teko", fontSize: "1.9vw" }}>
    //       {ADD_CONTRIBUTOR}
    //     </DialogTitle>
    //     <DialogContent>
    //       <DialogContentText style={{ fontFamily: "Teko", fontSize: "1.5vw" }}>{ADD_CONTRIBUTOR_TEXT}</DialogContentText>
    //       <TextField
    //         autoFocus
    //         id="email-input"
    //         label="&nbsp;&nbsp;&nbsp;Correo Electrónico"
    //         type="email"
    //         fullWidth
    //         variant="standard"
    //         style={{ marginBottom: "1rem" }}
    //         onChange={handleEmailChange}
    //         error={!!error}
    //         helperText={error}
    //       />
    //       <FormControl fullWidth>
    //         <InputLabel id="demo-simple-select-label">{ROLE}</InputLabel>
    //         <Select
    //           labelId="demo-simple-select-label"
    //           id="demo-simple-select"
    //           value={role}
    //           label={ROLE}
    //           onChange={handleChange}
    //         >
    //           <MenuItem value={"admin"}>{ADMIN_ROLE}</MenuItem>
    //           <MenuItem value={"editor"}>{CONTRIBUTOR_ROLE}</MenuItem>
    //         </Select>
    //       </FormControl>
    //     </DialogContent>
    //     <DialogActions>
    //       <Button onClick={handleClose} style={{ fontFamily: "Teko", fontSize: "1.5vw" }}>{CANCEL}</Button>
    //       <Button onClick={handleSubmit} style={{ fontFamily: "Teko", fontSize: "1.5vw" }}>{ADD}</Button>
    //     </DialogActions>
    //   </Dialog>
    //   <Backdrop
    //     sx={{ zIndex: 2000 }}
    //     open={openDrawer}
    //     onClick={handleDrawerClose} // Cierra el Drawer cuando se hace clic en el fondo
    //   />
    //   <Drawer
    //     PaperProps={{
    //       sx: {
    //         backgroundColor: "rgb(218, 255, 190)",
    //         color: "black",
    //         zIndex: 3000,
    //       },
    //     }}
    //     sx={{
    //       width: "20rem",
    //       flexShrink: 0,
    //       "& .MuiDrawer-paper": {
    //         width: "20rem",
    //         zIndex: 3000,
    //       },
    //     }}
    //     variant="persistent"
    //     anchor="right"
    //     open={openDrawer}
    //   >
    //     <IconButton
    //       style={{ width: "25%" }}
    //       onClick={handleDrawerClose}
    //       size="large"
    //       sx={{
    //         "&:hover": {
    //           backgroundColor: "transparent", // Establece el fondo a transparente en hover
    //         },
    //       }}
    //     >
    //       <ChevronRightIcon />
    //     </IconButton>
    //     <Divider />
    //     <List>
    //       <ListItem key={USER_SETTINGS_TITLE} disablePadding>
    //         <Button
    //           onClick={navigateUserSettings}
    //           className="w-full text-left"
    //           sx={{
    //             width: "100%",
    //             position: "relative",
    //             overflow: "hidden",
    //             "&:before": {
    //               content: '""',
    //               position: "absolute",
    //               left: 0,
    //               top: 0,
    //               width: "100%",
    //               height: "100%",
    //               background: "rgba(163, 230, 53, 0.5)",
    //               zIndex: -1,
    //               transition: "transform 0.3s ease", // Ajusta la duración y el efecto según tus preferencias
    //               transform: "translateX(-100%)", // Inicialmente, desplázalo completamente a la izquierda
    //             },
    //             "&:hover:before": {
    //               transform: "translateX(0)", // Al hacer hover, trasládalo completamente a la derecha
    //             },
    //           }}
    //         >
    //           <ListItemText primary={USER_SETTINGS_TITLE} />
    //         </Button>
    //       </ListItem>
    //       <ListItem key={LOGOUT_TEXT} disablePadding>
    //         <Form action="/logout" method="post" className="w-full">
    //           <Button
    //             type="submit"
    //             className="w-full text-left"
    //             sx={{
    //               width: "100%",
    //               position: "relative",
    //               overflow: "hidden",
    //               "&:before": {
    //                 content: '""',
    //                 position: "absolute",
    //                 left: 0,
    //                 top: 0,
    //                 width: "100%",
    //                 height: "100%",
    //                 background: "rgba(163, 230, 53, 0.5)",
    //                 zIndex: -1,
    //                 transition: "transform 0.3s ease", // Ajusta la duración y el efecto según tus preferencias
    //                 transform: "translateX(-100%)", // Inicialmente, desplázalo completamente a la izquierda
    //               },
    //               "&:hover:before": {
    //                 transform: "translateX(0)", // Al hacer hover, trasládalo completamente a la derecha
    //               },
    //             }}
    //           >
    //             <ListItemText primary={LOGOUT_TEXT} />
    //           </Button>
    //         </Form>
    //       </ListItem>
    //     </List>
    //   </Drawer>
    // </>
  );
};

export default ProductionHeader;

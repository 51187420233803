import React from "react";
import { NOT_FOUND_TITLE, NOT_FOUND_TEXT } from "../utils/constants";

const NotFound: React.FC = () => {
 return ( 
    <>
        <main>
            <h1>{NOT_FOUND_TITLE}</h1>
            <p>{NOT_FOUND_TEXT}</p>
        </main>
    </>
 )
}

export default NotFound;
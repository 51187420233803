import { Icon, LatLngExpression } from "leaflet";
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import classes from "./Map.module.css";

interface Props {
  value?: string;
  disabled?: boolean;
  onChange: (event: string) => void;
}

const RCMap: React.FC<Props> = ({ value, disabled, onChange }) => {
  const defaultCoordinates: LatLngExpression = [-34.90982125918208, -56.163991936683445];
  const [position, setPosition] = useState<LatLngExpression>(
    value ? (value.trim().split(",").map(Number) as [number, number]) : defaultCoordinates //[-34.903774, -56.190688]
  );

  const customIcon = new Icon({
    iconUrl:
      "https://static.vecteezy.com/system/resources/thumbnails/009/266/537/small_2x/location-icon-design-free-png.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  return (
    <MapContainer
      center={position}
      zoom={14}
      scrollWheelZoom={true}
      dragging={!disabled}
      className={classes.container}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapEventsHandler
        setPosition={setPosition}
        onChange={onChange}
        disabled={disabled}
        position={position}
      />
      <Marker position={position} icon={customIcon} />
    </MapContainer>
  );
};

const MapEventsHandler: React.FC<{
  setPosition: React.Dispatch<React.SetStateAction<LatLngExpression>>;
  onChange: (event: string) => void;
  disabled?: boolean;
  position: LatLngExpression;
}> = ({ setPosition, onChange, disabled, position }) => {
  const renderMap = useMap();

  useEffect(() => {
    if (disabled) return;
    if (position.toString() !== "0,0") return;
    renderMap.locate().on("locationfound", (e) => {
      renderMap.flyTo(e.latlng, renderMap.getZoom());
    });
  }, [renderMap, setPosition, disabled, position]);

  const map = useMapEvents({
    click(e) {
      if (disabled) return;
      setPosition(e.latlng);
      onChange(e.latlng.lat + "," + e.latlng.lng); //[lat, lng]?
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  // Render null, as useMapEvents handles the event listeners directly
  return null;
};

export default RCMap;

import { Box, IconButton, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/Twitter";
import React from "react";

interface Props {
  img: string;
  name: string;
  position: string;
  linkedin: string;
  //twitter: string
}
const MemberProfile: React.FC<Props> = ({
  img,
  name,
  position,
  linkedin,
  //, twitter
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img className="rounded-full grayscale w-60 md:w-[20vw] md:h-[18vw] md:p-[2.3vw]" src={img} alt="member"/>
      <Typography
        variant="h5"
        style={{ color: "white", fontFamily: "system-ui", fontSize: "1.6rem", textAlign:"center" }}
      >
        {name}
      </Typography>
      <Typography
        variant="h6"
        style={{ color: "white", fontFamily: "system-ui", fontSize: "1.1rem" }}
      >
        {position}
      </Typography>
      <Box>
        <IconButton
          aria-label="Linkedin"
          href={linkedin}
          component="a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon color="primary" />
        </IconButton>
        {/* <IconButton aria-label="Twitter">
          <TwitterIcon color="primary" />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default MemberProfile;

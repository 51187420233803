import { Typography } from "@mui/material";
import React from "react";

interface Props {
  isTitle: boolean;
  darkMode: boolean;
  text: string;
}

const SectionTitle: React.FC<Props> = ({ isTitle, darkMode, text }) => {
  return (
    <Typography
      variant={isTitle ? "h6" : "h1"}
      style={{
        color: "#282928",
        backgroundColor: darkMode ? "rgb(245, 250, 242)" : "rgb(245, 250, 242)",
        border: darkMode ? "0px solid white" : "0px solid white",
        borderRadius: "15px",
        fontFamily: "system-ui",
        fontSize: "1.7rem",
        textAlign: "center",
        padding: "1rem",
        boxShadow:"rgba(0, 0, 0, 0.1) 0px 0px 5px 2px"
      }}
      className={isTitle ? "w-80" : "w-60"}
    >
      {text}
    </Typography>
  );
};

export default SectionTitle;

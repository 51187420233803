import { json } from "react-router-dom";
import {
  createProductionFlow,
  createProductionListModel,
  createProductionModel,
} from "../adapters/Production.adapter";
import { CONNECTION_ERROR } from "../utils/constants";
import { Member } from "../models/Member.model";
import { Production } from "../models/Production.model";

export async function getProductionsCall(token: string | null, role: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/productions?role=` + role,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (!response.ok) {
      return json(
        { message: "No se pudieron cargar las producciones." },
        {
          status: 500,
        }
      );
    } else {
      const resData = await response.json();
      return createProductionListModel(resData);
    }
  } catch (error) {
    return json({ error: CONNECTION_ERROR });
  }
}

export async function addMemberCall(
  token: string | null,
  productionId: string | undefined,
  body: Member
) {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/productions/${productionId}/users`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    }
  );
  return response;
}

export async function createProductionCall(
  token: string | null,
  productionData: Production
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/productions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(productionData),
      }
    );

    if (!response.ok) {
      return json({ error: "No se pudo crear la nueva producción." });
    }
    

    return createProductionModel(await response.json());
  } catch (error) {
    return json({ error: CONNECTION_ERROR });
  }
}

export async function getProductionFlowCall(
  token: string | null,
  productionId: string | undefined
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/production-flow?productionId=${productionId}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (!response.ok) {
      return json(
        { message: "No se pudieron cargar las producciones." },
        {
          status: 500,
        }
      );
    } else {
      const resData = await response.json();
      return createProductionFlow(resData);
    }
  } catch (error) {
    return json({ error: CONNECTION_ERROR });
  }
}

export async function createProductionFlowCall(
  token: string | null,
  productionId: string | undefined,
  productionName: string | undefined
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/production-flow`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: productionName,
          productionId: productionId,
          stages: [
            {
              stageForm: {
                name: "Pre-siembra",
                stageStatus: "In Progress",
                logo: "presiembra.png"
              },
            },
            {
              stageForm: {
                name: "Siembra",
                stageStatus: "Blocked",
                logo: "siembra.png"
              },
            },
            {
              stageForm: {
                name: "Riego",
                stageStatus: "Blocked",
                logo: "riego.png"
              },
            },
            {
              stageForm: {
                name: "Cosecha",
                stageStatus: "Blocked",
                logo: "cosecha.png"
              },
            },
          ],
        }),
      }
    );

    if (!response.ok) {
      return json(
        { message: "No se pudieron cargar las producciones." },
        {
          status: 500,
        }
      );
    } 
    return;
  } catch (error) {
    return json({ error: CONNECTION_ERROR });
  }
}

import React from "react";
import ProductionForm from "../components/ProductionForm";
import ProductionsNavigation from "../components/ProductionsNavigation";

const NewProductionPage: React.FC = () => {
  return (
    <>
      <ProductionsNavigation />
      <ProductionForm />
    </>
  );
};

export default NewProductionPage;

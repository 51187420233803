import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";

interface Props {
  darkMode: boolean;
  text: string;
  selected?: boolean;
  disabled?: boolean;
  onChange: (event: string) => void;
}

const CheckboxItem: React.FC<Props> = ({
  darkMode,
  text,
  selected,
  disabled,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(text);
  };

  return (
    <FormControlLabel
      style={{
        border: "1.5px solid rgb(71 85 105 / 30%)",
        borderRadius: "16px",
        margin: "5px",
        height: "50px",
        textAlign: "left",
        minWidth: "11rem",
        background: "white",
      }}
      control={
        <Checkbox
          style={{ color: "#76885B", borderWidth: "1.5px", margin: "0px", fontFamily: "system-ui"}}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
          checked={selected ? selected : false}
          onChange={handleChange}
          disabled={disabled}
        />
      }
      label={
        <Typography
          style={{
            fontFamily: "system-ui", 
            fontSize: "0.9rem",
            textAlign: "center" 
          }}
        >
          {text}
        </Typography>
      }
    />
  );
};

export default CheckboxItem;

import React from "react";
import UserSettings from "../components/UserSettings";
import { updateUserDataCall } from "../services/Users.service";
import { getAuthToken } from "../utils/Auth";

const UserSettingsPage: React.FC = () => {
    const firstname = JSON.parse(localStorage.getItem("user") ?? "").firstName;
    const lastName = JSON.parse(localStorage.getItem("user") ?? "").lastName;

    return (
        <UserSettings lsname={firstname} lslastName={lastName} />
    )
}

export default UserSettingsPage;

export async function action({ request }: any) {
    const token = getAuthToken();
    const data = await request.formData();
    const userId = JSON.parse(localStorage.getItem("user") ?? "").id;
    var userData = {
        updatedFirstName: data.get("firstName") ?? "",
        updatedLastName: data.get("lastName") ?? "",
    };
    return await updateUserDataCall(token, userId, userData);
}